import { symbols } from '../useAppEnvironment';

export default {
  default: {
    languages: {
      de: 'Deutsch',
      en: 'Englisch',
      fr: 'Französisch',
      it: 'Italienisch',
      pl: 'Polnisch',
    },
    rename: 'Umbenennen',
    upload: 'Upload',
    delete: 'Löschen',
    duplicate: 'Duplizieren',
    moveUp: 'Nach oben',
    moveDown: 'Nach unten',
    stickersPlural: 'Sticker',
    save: 'Speichern',
    cancel: 'Abbrechen',
    learnMore: 'Mehr erfahren',
    settings: 'Einstellungen',
    search: 'Suchen',
    here: 'hier',
    undo: 'Rückgängig',
    redo: 'Wiederholen',
    on: 'an',
    off: 'aus',
    help: 'Hilfe',
    pagination: 'Seite %{page} von %{total}',
    genericSuccess: 'Erfolgreich gespeichert.',
    genericError: 'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
    [symbols.business]: 'Unternehmen',
    [symbols.wedding]: 'Hochzeit',
    [symbols.sports]: 'Sport',
    [symbols.birthday]: 'Geburtstag',
    [symbols.school]: 'Schule',
    [symbols.community]: 'Gemeinde',
    selectOption: 'Bitte wählen',
    back: 'Zurück',
    next: 'Weiter',
    createAlbum: 'Album erstellen',
    errorBoundary:
      'Bei der letzten Aktion ist leider etwas schiefgegangen. Unser Team wurde informiert. Dein letzter Stand wurde gespeichert.',
    render: {
      stickerpackText: 'Hier aufreissen',
    },
    start: {
      headline: 'Meine Alben',
      subline: 'Hier findest du die von dir erstellten Alben.',
      accountActivatedNotification: 'Dein Account wurde erfolgreich aktiviert.',
    },
    wizard: {
      autosaveModal: {
        headline: 'Schön, dass du zurück bist!',
        text:
          'Wir haben einen alten Konfigurationsstand gefunden. Möchtest du diesen laden oder neu beginnen?',
        confirm: 'Ja, bitte laden',
        decline: 'Neu beginnen',
      },
      pageHint: 'Benutze die Buttons, um durch die Vorschau zu blättern!',
      tabs: {
        blueprints: {
          headline: 'Wähle eine Vorlage',
          content:
            'Unsere Grafiker haben tolle Vorlagen für dich gestaltet. Wähle deine Wunschvorlage und passe sie im nächsten Schritt nach deinem persönlichem Geschmack an.',
        },
        data: {
          headline: 'Fast geschafft!',
          content: "Ein paar letzte Infos, dann geht's los!",
          organizationName: 'Name des Unternehmens',
          numEmployees: 'Anzahl Mitarbeiter:innen',
          contactName: 'Dein Name',
          phone: 'Telefonnummer',
          phoneDescription:
            'Im Telefonkontakt können wir dir den besten Service bieten.',
          contactRole: 'Position',
          contactRoleDescription:
            'Was beschreibt deine Rolle im Unternehmen am besten?',
          contactRoles: {
            member: 'Team-Mitglied',
            lead: 'Team-Lead / Projekt-Manager:in',
            senior: 'Senior-Management',
            executive: 'Geschäftsführung',
          },
        },
      },
    },
    confirmModal: {
      defaultHeadline: 'Bist du sicher?',
      defaultConfirm: 'Ich bin sicher',
    },
    editor: {
      autosave: {
        connectionError:
          'Fehler bei Server-Verbindung, möglicherweise werden deine Änderungen nicht gespeichert.',
        mergeError:
          'Dein Album konnte nicht gespeichert werden, wir werden es weiter versuchen.',
      },
      meta: {
        orderNow: 'Jetzt bestellen',
        priceLabel: 'ab 285 €',
        currentSpread: 'Aktuelle Seite',
        allSpreads: 'Alle Seiten',
        grid: 'Raster',
        elements: 'Seitenelemente',
        showBleed: 'Endformat anzeigen',
        terms: 'AGB',
        customerInformation: 'Kundeninformationen',
        privacy: 'Datenschutz',
        contact: 'Kontakt aufnehmen',
        mobileAlert:
          'Auf dem Laptop ist es einfacher! Wechsle an ein Gerät mit größerem Bildschirm, um dein Album zu gestalten.',
        mobileCta: 'Gerät wechseln',
        mobileModalHeadline: "Hier geht's weiter",
        mobileModalContent:
          'Klicke auf den Button – wir schicken dir einen Link per E-Mail, mit dem du dein Album auf dem Laptop weiter bearbeiten kannst.',
        mobileModalCta: 'Link senden',
        mobileModalConfirmation: 'Alles klar, check deinen Posteingang!',
        profile: 'Profil',
        logout: 'Abmelden',
        saveError: 'Es gab ein Problem beim Speichern Deines Albums',
        stickerCellDropError:
          'Jedem Stickerfeld kann nur ein Bild hinzugefügt werden.',
        printPreview: {
          preview: 'Druckvorschau',
          turnOn: 'Druckvorschau einschalten',
          turnOff: 'Druckvorschau ausschalten',
        },
      },
      imageUpload: {
        maxImagesError:
          'Es können bis zu %{max} Bilder gleichzeitig hochgeladen werden.',
        uploadError: 'Es gab ein Problem beim Upload von %{fileName}.',
        sizeError: 'Bild muss zwischen %{min} und %{max} mb groß sein.',
        typeError: 'Bild muss vom Typ %{types} sein.',
        dimensionsError: 'Bild darf max. %{maxPixels} Pixel haben.',
      },
      spreadMeta: {
        moveUp: 'Seite nach oben schieben',
        moveDown: 'Seite nach unten schieben',
        duplicate: 'Seite duplizieren',
        delete: 'Seite löschen',
        deleteConfirmation:
          'Wenn du die Doppelseite löschst, werden auch alle darauf platzierten Elemente gelöscht (Text, Stickerfelder, Formen etc.)',
        pageRange: 'Seiten %{start} - %{end}',
        addSpread: 'Leere Seite einfügen',
      },
      toolbar: {
        fill: 'Füllen',
        strokeColor: 'Randfarbe',
        strokeWidth: 'Randstärke',
        opacity: 'Transparenz',
        fontFamily: 'Schriftart',
        fontSize: 'Schriftgröße',
        textColor: 'Textfarbe',
        lineHeightAndAlign: 'Zeilenhöhe & Ausrichtung',
        alignLeft: 'Linksbündig',
        alignCenter: 'Zentriert',
        alignRight: 'Rechtsbündig',
        alignJustify: 'Blocksatz',
        lineHeight: 'Zeilenhöhe',
        symbolText: 'Automatischer Text',
        group: 'Gruppieren',
        ungroup: 'Gruppierung auflösen',
        finishEditing: 'Bearbeitung beenden',
        editImage: 'Bild bearbeiten',
        uploadImage: 'Bild hochladen',
        flip: 'Spiegeln',
        toFront: 'Nach vorne',
        toBack: 'Nach hinten',
        editSticker: 'Sticker bearbeiten',
        uploadStickerImage: 'Stickerbild hochladen',
        editText: 'Text bearbeiten',
        showLess: 'weniger...',
        showMore: 'mehr...',
        title: 'Überschrift',
        subline: 'Untertitel',
        text: 'Text',
        bold: 'Fett',
        italic: 'Kursiv',
        uppercase: 'Großbuchstaben',
        fontSizeAutoFitHint:
          'Schriftgröße wird ggfs. verkleinert, damit der Text in eine Zeile passt',
        autoFit: 'Einpassen',
        automaticText: 'Automatischer Text',
        freeText: '(Freier Text)',
        section: 'Name des Kapitels',
        album: 'Titel des Albums',
        release_date: 'Tag des Events',
        pagenum: 'Seitenzahl',
        totalpages: 'Gesamtseitenzahl',
        totalstickers: 'Gesamtstickeranzahl',
        toc_sections: 'Inhaltsverzeichnis / Kapitel',
        toc_pages: 'Inhaltsverzeichnis / Seiten',
        unlinkSticker: 'Sticker ablösen',
        newText: 'Neuer Text...',
        symbolNote:
          'Dies ist ein automatischer Text, der nicht editiert werden kann.',
      },
      elements: {
        text: 'Textfeld',
        circle: 'Kreis',
        rectangle: 'Rechteck',
        line: 'Linie',
        stickerCell: 'Stickerfläche',
        comment: 'Kommentar',
      },
      albumTitle: {
        savedMessage: 'Zuletzt gespeichert am %{date}',
        titlePlaceholder: 'Titel des Albums',
        releaseDate: 'Tag des Events',
        releaseDateTooltip:
          'Tag des Events – wann soll dein Album fertig sein?',
      },
      onboarding: {
        headline: 'Herzlich Willkommen',
        subline:
          'im Designer! Auf dieser Seite findest du alles, was du zum Start benötigst. Falls doch Fragen auftreten sollten, melde dich gerne jederzeit bei uns.',
        toAlbum: 'Zum Album',
        salesContactAlert: `Mit einer Organisation dieser Größe habt ihr einen festen
          Ansprechpartner; gerne übernehmen wir Konzept, Design und
          Projektmanagement für euch. Tragt euch für einen unverbindlichen
          Austausch gerne in meinen Kalender ein:`,
        schedulerLink: 'Termin vereinbaren',
        usefulLinks: 'Nützliche Links',
        tutorial: {
          headline: 'Tutorial lesen',
          subline:
            'Alles ganz einfach: Unser Tutorial hilft dir bei der Erstellung deines Albums.',
        },
        faq: {
          headline: 'Häufige Fragen',
          subline:
            'Hier findest du unsere Antworten auf die häufigsten Fragen zum Sammelerlebnis.',
        },
        budget: {
          headline: 'Budget',
          subline:
            'Klicke hier, um zum Preisrechner zu gelangen und dein Budget zu planen.',
        },
        firstSteps: 'Erste Schritte',
        getToKnowDesigner: 'Lerne den Designer kennen',
        steps: {
          team: {
            headline: 'Lade dein Team ein',
            text:
              "So geht's schneller! Lade die Menschen ein, die dich bei der Erstellung deines Albums unterstützen.",
          },
          albumTitle: {
            headline: 'Passe den Titel und Tag des Events an',
            text:
              'Gib deinem Album einen Namen und lege den Tag des Events fest.',
          },
          sticker: {
            headline: 'Erstelle deinen ersten Sticker',
            text:
              'Klicke hier, um zur Stickerverwaltung zu gelangen – dort kannst du deine ersten Sticker erstellen.',
          },
          section: {
            headline: 'Passe die Kapitel an',
            text:
              'Die Kapitel strukturieren dein Album. Klicke hier, um zur Kapitelverwaltung zu gelangen.',
          },
          stickerlink: {
            headline: 'Bereite deinen Stickerlink vor',
            text:
              'Über den Stickerlink können deine Stickerstars ihre eigenen Fotos für Sticker hochladen.',
          },
          image: {
            headline: 'Platziere ein Hintergrundbild',
            text:
              'Ziehe ein Foto auf eine der Seiten, um ein Hintergrundfoto zu platzieren.',
          },
        },
      },
      sidebar: {
        tabs: {
          onboarding: 'Start',
          stickers: 'Sticker',
          stickerlink: 'Stickerlink',
          background: 'Hintergrund',
          layouts: 'Layouts',
          styles: 'Design',
          jobs: 'Export',
          review: 'Feedback',
        },
        stickers: {
          expand: 'Kapitel ausklappen',
          collapse: 'Kapitel einklappen',
          search: 'Sticker suchen',
          newStickers: 'Neue Sticker',
          newStickersNotification:
            'Über deinen Stickerlink wurden <b>%{count} neue Sticker</b> hochgeladen.',
          collectStickers: 'Sticker einsammeln',
          unplacedStickers: 'Unplatzierte Sticker',
          stickerPlacementHelp: 'Sticker platzieren',
          goToSection: 'Gehe zu Kapitel',
          sectionHelp: 'Hilfe zu Kapiteln',
          addSection: 'Kapitel hinzufügen',
          sectionDeleteWarning:
            'Wenn du das Kapitel löschst, werden auch alle zugehörigen Sticker und Seiten gelöscht.',
          stickerDeleteWarning:
            'Wenn du den Sticker löschst, wird auch das zugehörige Stickerbild endgültig gelöscht. (Das gilt auch für Bilder, die du über den Stickerlink eingesammelt hast.)',
          defaultStickerName: 'Neuer Sticker',
        },
        stickerlink: {
          status: 'Status',
          headline: '%{count} Sticker eingesammelt',
          sublineActive: 'Dein Stickerlink ist aktiv.',
          sublineInactive: 'Dein Stickerlink ist inaktiv.',
          yourLink: 'Dein Stickerlink',
          description:
            'Mit dem Stickerlink kannst du ganz bequem und schnell die Fotos aller Personen einsammeln! Einfach kopieren und verschicken.',
          copyLink: 'Link in die Zwischenablage kopieren',
          isActive: 'Stickerlink aktiv',
          isActiveHint: 'Foto-Upload aktivieren/deaktivieren',
          title: 'Titel abfragen',
          titleHint:
            'Zeigt ein Feld an, über das der Titel des Stickers abgefragt wird',
          surpriseMode: 'Überraschungsmodus',
          surpriseModeHint:
            'Enfernt alle Hinweise auf Stickerstars und das Produkt',
          anonymousUrl: 'Anonyme URL',
          message: 'Persönliche Nachricht',
          viewLink: 'Link ansehen',
        },
        images: {
          ownImages: 'Eigene Bilder',
          stockImages: 'Bibliothek',
          imagesPlural: 'Bilder',
          help: 'Hilfe zu Hintergründen',
          hint:
            'In diesem Bereich kannst du Hintergrundfotos für dein Album hochladen. Fotos für deine Sticker erstellst und verwaltest du',
          stockHint:
            'Hier findest du eine große Auswahl an kostenlosen Bildern für dein Album. Gib einfach im Suchfeld einen Begriff oder ein Thema ein. Ziehe ein Bild auf die Zeichenfläche, um es im Album zu platzieren.',
          maxImagesError:
            'Es können maximal %{maxImages} Bilder gleichzeitig hochgeladen werden.',
          deleteWarning:
            'Wenn du das Hintergrundbild löschst, wird es auch von allen Albumseiten entfernt.',
          stockSearchPlaceholder: 'Bibliothek durchsuchen...',
          stickerDropWarning:
            'Möchtest du aus diesem Hintergrundbild einen Sticker machen? Alternativ kannst du Sticker-Bilder auch unter "Sticker" hochladen.',
        },
        layouts: {
          hint:
            'Hier findest du eine Vielzahl an Seiten-Layouts zur Gestaltung deines Albums. Ziehe sie einfach auf eine (leere oder bestehende) Seite auf der Zeichenfläche, um sie im Album zu platzieren.',
          placeWarning:
            'Wenn du ein Layout auf eine Seite mit Inhalt anwendest, geht deren Inhalt verloren. Bist du sicher?',
        },
        styles: {
          headline: 'Stil anpassen',
          colors: 'Farbstil ändern',
          fonts: 'Schrifstil ändern',
          stickers: 'Sticker-Design ändern',
          tryPresets: 'Vorlagen ausprobieren',
          custom: 'Eigene Farben eingeben',
          changeColor: 'Wähle eine Farbe zum Verändern',
          primaryColor: 'Hauptfarbe',
          secondaryColor: 'Zweitfarbe',
          tertiaryColor: 'Drittfarbe',
          yourColors: 'Dein Farbstil',
        },
        review: {
          noComments:
            'Wir haben keine Hinweise für dich, dein Album ist druckreif!',
          sendFeedback: 'Feedback senden',
          printApproval: 'Druckfreigabe',
          printApprovalTooltip:
            'Hast du alle Kommentare berücksichtigt? Dann kannst du uns hier dein OK für den Druck geben. Änderungen sind danach nicht mehr möglich',
        },
      },
      stockPanel: {
        defaultQuery: 'Textur',
      },
      stickerUploadsModal: {
        selectStickerHint: 'Wähle einen Sticker aus, um ihn zu bearbeiten.',
        headline: 'Uploads (%{count})',
        submit: '%{count} Sticker hinzufügen',
      },
      stickerForm: {
        headline: 'Sticker bearbeiten',
        namePlaceholder: 'Namen eingeben',
        positionPlaceholder: 'Position eingeben',
        doubleSticker: 'Doppelsticker',
        addImage: 'Foto hinzufügen',
        changeImage: 'Foto austauschen',
        downloadImage: 'Foto herunterladen',
        fitFace: 'Gesicht einpassen',
        noFace: 'Kein Gesicht gefunden',
        stickerLayout: 'Layout',
        stickerCell: 'Klebefläche',
        deleteWarning:
          'Wenn du den Sticker löschst, wird auch das zugehörige Stickerbild endgültig gelöscht. (Das gilt auch für Bilder, die du über den Stickerlink eingesammelt hast.)',
      },
    },
    stickerUploadForm: {
      nameLabel: 'Dein Name',
      namePlaceholder: 'Dein Name',
      dataProtection: 'Wir passen gut auf deine Daten auf.',
      dataProtectionLink: 'Hier',
      dataProtectionInfo: 'gibt es Informationen zum Datenschutz.',
      lastNameLabel: 'Dein Nachname (optional)',
      lastNamePlaceholder: 'Dein Nachname',
      positionLabel: 'Titel/Position (optional)',
      positionPlaceholder: 'Dein Titel',
      photoLabel: 'Dein Foto',
      changePhoto: 'ändern',
      uploadInstructions:
        'Wähle ein Bild von der Festplatte oder benutze dein Smartphone, um direkt eins zu schießen!',
      successHeading: 'Hat geklappt 👍',
      successMessage:
        'Dein Upload war erfolgreich, vielen Dank dafür! Jetzt ist Zeit für Vorfreude.',
      restartButton: 'von vorne',
      sectionLabel: 'Dein Kapitel',
      dontKnow: 'Ich weiß es nicht',
      sectionHelp:
        'Wenn du nicht weißt, was du hier angeben sollst, bleibe einfach bei "Ich weiß es nicht".',
      uploadingHeading: 'Lade hoch...',
      uploadingMessage:
        'Vielen Dank für Deine Teilnahme — wir laden grade die Daten hoch.',
      progressLabel: 'Fortschritt',
      uploaderHeading: 'Willkommen im Foto-Uploader',
      backButton: 'zurück',
      nextButton: 'weiter',
      privacyLink: 'Datenschutz',
      imprintLink: 'Impressum',
      title: 'Stickerstars | Foto-Uploader',
      titleWhiteLabel: 'Foto-Uploader',
      loading: 'Lade...',
      albumError:
        'Dieses Album existiert nicht oder akzeptiert im Moment keine Uploads.',
    },
    stickerTemplate: {
      name: 'Lisa Lässig',
      position: 'Schwester der Braut',
    },
    profile: {
      headline: 'Mein Profil',
      subline: 'Informationen zu deinem Nutzeraccount',
      ordersHeadline: 'Meine Bestellungen',
      showDetails: 'Details anzeigen',
    },
    showOrder: {
      headline: 'Bestelldetails',
      subline: 'Hier findest du alle Informationen zu deinem Auftrag.',
      details: 'Details',
      shipping: 'Versand',
      timeline: 'Timeline',
    },
    checkout: {
      backToAlbum: 'zurück zum Album',
      title: 'Deine Bestellung',
      errorMessage:
        'Hoppla, da ist etwas schiefgelaufen. Versuch es bitte nochmal.',
      personalInfoMessage:
        'Super, jetzt benötigen wir nur noch ein paar persönliche Informationen von dir – dann hast du es geschafft.',
      previewConfirmation: 'Sieht alles so aus, wie du es dir vorstellst?',
      firstNameRequired: 'Vorname muss ausgefüllt werden',
      lastNameRequired: 'Nachname muss ausgefüllt werden',
      emailInvalid: 'E-Mail ist ungültig',
      addressRequired: 'Straße und Hausnummer muss ausgefüllt werden',
      zipRequired: 'PLZ muss ausgefüllt werden',
      cityRequired: 'Stadt muss ausgefüllt werden',
      countryRequired: 'Land muss ausgefüllt werden',
      phoneNumberFormat:
        'Telefonnummer darf nur Zahlen und Leerzeichen enthalten',
      phoneNumberLength: 'Telefonnummer darf max. 20 Zeichen lang sein',
      commentLength: 'Kommentar darf max. 500 Zeichen lang sein',
      companyRequired: 'Unternehmen muss ausgefüllt werden',
      clubRequired: 'Verein muss ausgefüllt werden',
      printConsent:
        'Für den Produktionsprozess gewähre ich Stickerstars Zugriff auf mein Album.',
      orderWithCost: 'Kostenpflichtig bestellen',
      backToOrderDetails: 'Zurück zu den Bestelldetails',
      orderConfirmation: 'Vielen Dank, dein Auftrag wird bearbeitet.',
      nextStepPackageContents: 'Nächster Schritt: Deine Paketinhalte',
      eventDateRequired: 'Tag des Events muss ausgefüllt werden',
      nextStepYourData: 'Nächster Schritt: Deine Daten',
      backToAlbumPreview: 'Zurück zur Album-Vorschau',
      contactHeading: 'Kontakt',
      email: 'E-Mail',
      deliveryBillingAddress: 'Liefer-/Rechnungsadresse',
      firstName: 'Vorname',
      lastName: 'Nachname',
      streetAndNumber: 'Straße und Hausnummer',
      addressAddition: 'Adresszusatz',
      optional: 'optional',
      zipCode: 'Postleitzahl',
      city: 'Stadt',
      country: 'Land',
      phone: 'Telefon',
      quoteNumber: 'Angebotsnummer',
      remarks: 'Anmerkungen',
      commentPlaceholder: 'Hast du noch etwas auf dem Herzen? Schreib uns.',
      albumsLabel: 'Anzahl Alben wählen',
      albumsNeeded: 'Ich benötige <b>%{count} Alben</b>',
      chooseAdditionalOptions: 'Wähle aus unseren Zusatzoptionen',
      eventLabel: 'Wann findet dein Event statt?',
      orderSummary: 'Bestellübersicht',
      priceIncludingShipping: 'Preis inkl. Versand',
      vat: 'MwSt. / VAT',
      totalCost: 'Gesamtkosten',
      estimatedDeliveryDate: 'vsl. Zustelltermin: <b>%{date}</b>',
      deliveryWarning:
        'Das könnte knapp werden. Bitte melde dich bei uns, bevor du die Bestellung aufgibst.',
      voucherInvalid: 'Dieser Code ist leider nicht gültig',
      voucherInactive: 'Dieser Code ist nicht mehr aktiv',
      voucherTaken: 'Dieser Code wurde bereits genutzt',
      voucherApplied: 'Dein Gutschein %{code} wurde angewendet! 🎉',
      haveVoucher: 'Hast du einen Gutschein?',
      voucherCodePlaceholder: 'Gutschein-Code',
      organizationLabel: 'Organisation',
      countryLabels: {
        DE: 'Deutschland',
        CH: 'Schweiz',
        AT: 'Österreich',
        LU: 'Luxemburg',
        FR: 'Frankreich',
        NL: 'Niederlande',
        BE: 'Belgien',
        IE: 'Irland',
        FI: 'Finnland',
        IT: 'Italien',
        PL: 'Polen',
      },
      stickerPackagingOptionsLabel: 'Wie dürfen wir deine Sticker verpacken?',
      stickerPackagingOptions: {
        stickerpack: {
          title: 'Stickerpacks',
          text:
            'Unser Klassiker: Jedes Pack enthält 4 zufällig gemischte Sticker für ein Überraschungsmoment beim Öffnen – perfekt für eine lebendige und interaktive Sammelaktion, bei der Gäste doppelte Sticker tauschen und ihre Alben gemeinsam vervollständigen können.',
        },
        stickerboxSingleVariety: {
          title: 'Vorsortierte Stickerschachteln',
          text:
            'Unsere "Visitenkarten-Option": Jede Schachtel enthält ausschließlich identische Sticker eines Motivs. Diese Variante eignet sich ideal für Veranstalter:innen, die Gästen ihre eigenen Sticker in vielfacher Ausführung bereitstellen möchten.',
        },
      },
    },
    settingsModal: {
      title: 'Einstellungen',
      generalTab: 'Generell',
      usersTab: 'Nutzer verwalten',
      emailNotifications: 'E-Mail-Benachrichtigungen',
      emailNotificationsHelp:
        'Wir schicken dir z. B. eine Benachrichtigung, wenn über den Sticker-Link neue Sticker in dein Album geladen werden.',
      albumPreview: 'Album-Vorschau',
      albumPreviewHelp:
        'Aktiviere die Album-Vorschau, um einen teilbaren Link zu generieren. Der Link berechtigt nur zur Ansicht, nicht zur Bearbeitung. Achtung: Mit diesem Link kann jede:r dein Album einsehen. Teile ihn nur mit dir vertrauten Personen.',
      saveError: 'Fehler beim Speichern',
      language: 'Sprache des Albums',
      languageHelp:
        'Die Sprache des Albums steuert die Darstellung von Daten und Zahlen.',
      users: {
        error:
          'Das hat leider nicht funktioniert. Bitte versuche es später noch einmal.',
        inviteUser: 'Nutzer einladen',
        emailPlaceholder: 'E-Mail-Adresse',
        sendInvitation: 'Einladung senden',
        cancel: 'Abbrechen',
        usersHeader: 'Nutzer',
        ownerBadge: 'Besitzer',
        removeUser: 'Entfernen',
        invitationsHeader: 'Offene Einladungen',
        cancelInvitation: 'Abbrechen',
        noInvitations: 'Keine offenen Einladungen.',
        invitationsError: 'Ein Fehler ist aufgetreten.',
      },
    },
    notifications: {
      previewMode:
        'Dein Album befindet sich im Vorschau-Modus, Änderungen sind aktuell leider nicht möglich.',
      autoSave: 'Deine Änderungen werden automatisch gespeichert.',
    },
    invitations: {
      error:
        'Das hat leider nicht funktioniert. Bitte versuche es später noch einmal.',
      joinMessage:
        '<b>%{inviterEmail}</b> hat dich eingeladen, dem Stickerprojekt <b>%{albumTitle}</b> beizutreten. Um fortzufahren, klicke bitte auf den Button und melde dich an oder erstelle ein Konto:',
      acceptInvitation: 'Einladung annehmen',
      accessInfo:
        'Nachdem du dich angemeldet hast, erhältst du Zugriff auf Album und Sticker und kannst mit der Bearbeitung beginnen.',
      moreInfo:
        'Hier findest du weitere Informationen zu STICKERSTARS und eurem Projekt.',
      companyInfo:
        'STICKERSTARS Technologies GmbH, Wilhelm-Kabus-Str. 42/44, 10829 Berlin, Deutschland',
    },
    models: {
      title: 'Titel',
      createdAt: 'Erstellt am',
      album: {
        one: 'Album',
        plural: 'Alben',
        releaseDate: 'Tag des Events',
      },
      order: {
        id: 'Bestellnummer',
        lineItems: 'Artikel',
        net: 'Netto',
        vat: 'MwSt.',
        deliveryNote: 'Lieferschein',
        invoice: 'Rechnung',
        comment: 'Kommentar',
      },
      lineItem: {
        quantity: 'Menge',
      },
    },
    cookies: {
      title: '🍪 Cookies und Datenschutz',
      description:
        'Um dir das bestmögliche Surferlebnis zu ermöglichen, würden wir gerne ein paar Cookies speichern! Nähere Informationen dazu findest du in unserer <a target="__blank" aria-label="Link zur Datenschutzseite" class="cc-link" href="https://stickerstars.de/datenschutz">Datenschutzerklärung</a>. Bitte klicke auf „Ich stimme zu", um die Cookies zu akzeptieren und unsere Website besuchen zu können!',
      acceptAll: 'Ich stimme zu',
      settings: 'Einstellungen',
      settingsModal: {
        title: 'Cookie-Einstellungen',
        saveSettings: 'Einstellungen speichern',
        acceptAll: 'Alle akzeptieren',
        rejectAll: 'Alle ablehnen',
        close: 'schließen',
        intro:
          'Wir verwende Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und um dein Online-Erlebnis zu verbessern. Du kannst für jede Kategorie wählen, ob Du diese Cookies akzeptierst -- oder nicht.',
        necessary: {
          title: 'Notwendige Cookies',
          description:
            'Notwendige Cookies (und ähnliche Technologien) sind für einen reibungslosen Betrieb der Webseite erforderlich und können nicht deaktiviert werden.',
        },
        analytics: {
          title: 'Cookies für Analysen und Marketing',
          description:
            'Diese Cookies sammeln Informationen darüber, wie du die Website nutzt, welche Seiten du besuchst und welche Links du angeklickt hast. Alle Daten sind anonymisiert und können nicht verwendet werden, um dich zu identifizieren',
        },
        moreInfo: {
          title: 'Weitere Informationen',
          description:
            'Für weitere Informationen zu unserer Cookie-Policy und unseren Datenschutzrichtlinien besuche bitte unsere <a class="cc-link" target="_BLANK" href="https://www.stickerstars.de/datenschutz">Datenschutz</a>-Seite.',
        },
      },
    },
    footer: {
      imprint: 'Impressum',
      privacy: 'Datenschutz',
    },
  },
  business: {
    editor: {
      stockPanel: {
        defaultQuery: 'Teamwork',
      },
    },
    stickerTemplate: {
      name: 'Agathe Anklam',
      position: 'CFO',
    },
    checkout: {
      organizationLabel: 'Unternehmen',
    },
  },
  sports: {
    editor: {
      stockPanel: {
        defaultQuery: 'Sport',
      },
      stickerTemplate: {
        name: 'Jutta Juist',
        position: 'Rechtes Mittelfeld',
      },
    },
    checkout: {
      organizationLabel: 'Verein / Organisation',
    },
  },
};
