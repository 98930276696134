import { symbols } from '../useAppEnvironment';

export default {
  default: {
    languages: {
      de: 'Tedesco',
      en: 'Inglese',
      fr: 'Francese',
      it: 'Italiano',
      pl: 'Polacco',
    },
    rename: 'Rinomina',
    upload: 'Carica',
    delete: 'Elimina',
    duplicate: 'Duplica',
    moveUp: 'Sposta su',
    moveDown: 'Sposta giù',
    stickersPlural: 'Figurine',
    save: 'Salva',
    cancel: 'Annulla',
    learnMore: 'Scopri di più',
    settings: 'Impostazioni',
    search: 'Cerca',
    here: 'qui',
    undo: 'Annulla',
    redo: 'Ripeti',
    on: 'attivo',
    off: 'disattivo',
    help: 'Aiuto',
    pagination: 'Pagina %{page} di %{total}',
    genericSuccess: 'Salvato con successo.',
    genericError: 'Si è verificato un errore. Riprova per favore.',
    [symbols.business]: 'Azienda',
    [symbols.wedding]: 'Matrimonio',
    [symbols.sports]: 'Sport',
    [symbols.birthday]: 'Compleanno',
    [symbols.school]: 'Scuola',
    [symbols.community]: 'Comunità',
    selectOption: 'Seleziona',
    back: 'Indietro',
    next: 'Avanti',
    createAlbum: 'Crea album',
    errorBoundary:
      "Qualcosa è andato storto durante l'ultima azione. Il nostro team è stato informato. Il tuo ultimo stato è stato salvato.",
    render: {
      stickerpackText: 'Apri qui',
    },
    start: {
      headline: 'I miei album',
      subline: 'Qui trovi gli album che hai creato.',
      accountActivatedNotification:
        'Il tuo account è stato attivato con successo.',
    },
    wizard: {
      autosaveModal: {
        headline: 'Bentornato!',
        text:
          'Abbiamo trovato una configurazione precedente. Vuoi caricarla o iniziare da capo?',
        confirm: 'Sì, carica',
        decline: 'Inizia da capo',
      },
      pageHint: "Usa i pulsanti per sfogliare l'anteprima!",
      tabs: {
        blueprints: {
          headline: 'Scegli un modello',
          content:
            'I nostri grafici hanno creato dei fantastici modelli per te. Scegli quello che preferisci e personalizzalo secondo i tuoi gusti nel prossimo step.',
        },
        data: {
          headline: 'Ci siamo quasi!',
          content: 'Ancora alcune informazioni e poi si parte!',
          organizationName: "Nome dell'azienda",
          numEmployees: 'Numero di dipendenti',
        },
      },
    },
    confirmModal: {
      defaultHeadline: 'Sei sicuro?',
      defaultConfirm: 'Sono sicuro',
    },
    editor: {
      autosave: {
        connectionError:
          'Errore di connessione al server, le tue modifiche potrebbero non essere salvate.',
        mergeError:
          'Impossibile salvare il tuo album, continueremo a riprovare.',
      },
      meta: {
        orderNow: 'Ordina ora',
        priceLabel: 'a partire da 285 €',
        printPreview: 'Anteprima di stampa',
        currentSpread: 'Pagina attuale',
        allSpreads: 'Tutte le pagine',
        grid: 'Griglia',
        elements: 'Elementi pagina',
        showBleed: 'Mostra formato finale',
        terms: 'Termini e condizioni',
        customerInformation: 'Informazioni cliente',
        privacy: 'Privacy',
        contact: 'Contattaci',
        mobileAlert:
          'È più facile su laptop! Passa a un dispositivo con schermo più grande per progettare il tuo album.',
        mobileCta: 'Cambia dispositivo',
        mobileModalHeadline: 'Continua qui',
        mobileModalContent:
          'Clicca sul pulsante - ti invieremo un link via email per continuare a modificare il tuo album sul laptop.',
        mobileModalCta: 'Invia link',
        mobileModalConfirmation: 'Perfetto, controlla la tua casella di posta!',
        profile: 'Profilo',
        logout: 'Esci',
        saveError:
          'Si è verificato un problema durante il salvataggio del tuo album',
        stickerCellDropError:
          'Puoi aggiungere una sola immagine per ogni spazio figurina.',
      },
      imageUpload: {
        maxImagesError:
          'Puoi caricare fino a %{max} immagini contemporaneamente.',
        uploadError:
          'Si è verificato un problema durante il caricamento di %{fileName}.',
        sizeError: "L'immagine deve essere tra %{min} e %{max} mb.",
        typeError: "L'immagine deve essere di tipo %{types}.",
        dimensionsError: "L'immagine non può superare %{maxPixels} pixel.",
      },
      spreadMeta: {
        moveUp: 'Sposta la pagina in alto',
        moveDown: 'Sposta la pagina in basso',
        duplicate: 'Duplica pagina',
        delete: 'Elimina pagina',
        deleteConfirmation:
          'Se elimini la doppia pagina, verranno eliminati anche tutti gli elementi posizionati su di essa (testo, spazi figurine, forme, ecc.)',
        pageRange: 'Pagine %{start} - %{end}',
        addSpread: 'Inserisci pagina vuota',
      },
      toolbar: {
        fill: 'Riempi',
        strokeColor: 'Colore bordo',
        strokeWidth: 'Spessore bordo',
        opacity: 'Trasparenza',
        fontFamily: 'Carattere',
        fontSize: 'Dimensione testo',
        textColor: 'Colore testo',
        lineHeightAndAlign: 'Interlinea e allineamento',
        alignLeft: 'Allinea a sinistra',
        alignCenter: 'Centra',
        alignRight: 'Allinea a destra',
        alignJustify: 'Giustifica',
        lineHeight: 'Interlinea',
        symbolText: 'Testo automatico',
        group: 'Raggruppa',
        ungroup: 'Separa',
        finishEditing: 'Termina modifica',
        editImage: 'Modifica immagine',
        uploadImage: 'Carica immagine',
        flip: 'Rifletti',
        toFront: 'Porta avanti',
        toBack: 'Porta indietro',
        editSticker: 'Modifica figurina',
        uploadStickerImage: 'Carica immagine figurina',
        editText: 'Modifica testo',
        showLess: 'meno...',
        showMore: 'altro...',
        title: 'Titolo',
        subline: 'Sottotitolo',
        text: 'Testo',
        bold: 'Grassetto',
        italic: 'Corsivo',
        uppercase: 'Maiuscolo',
        fontSizeAutoFitHint:
          'La dimensione del carattere potrebbe essere ridotta per far stare il testo in una riga',
        autoFit: 'Adatta',
        automaticText: 'Testo automatico',
        freeText: '(Testo libero)',
        section: 'Nome del capitolo',
        album: "Titolo dell'album",
        release_date: "Data dell'evento",
        pagenum: 'Numero pagina',
        totalpages: 'Totale pagine',
        totalstickers: 'Totale figurine',
        toc_sections: 'Indice / Capitoli',
        toc_pages: 'Indice / Pagine',
        unlinkSticker: 'Scollega figurina',
        newText: 'Nuovo testo...',
        symbolNote:
          'Questo è un testo automatico che non può essere modificato.',
      },
      elements: {
        text: 'Campo testo',
        circle: 'Cerchio',
        rectangle: 'Rettangolo',
        line: 'Linea',
        stickerCell: 'Spazio figurina',
        comment: 'Commento',
      },
      sidebar: {
        tabs: {
          onboarding: 'Inizio',
          stickers: 'Figurine',
          stickerlink: 'Link figurine',
          background: 'Sfondo',
          layouts: 'Layout',
          styles: 'Design',
          jobs: 'Export',
          review: 'Feedback',
        },
        stickers: {
          expand: 'Espandi capitolo',
          collapse: 'Comprimi capitolo',
          search: 'Cerca figurine',
          newStickers: 'Nuove figurine',
          newStickersNotification:
            'Tramite il tuo link figurine sono state caricate <b>%{count} nuove figurine</b>.',
          collectStickers: 'Raccogli figurine',
          unplacedStickers: 'Figurine non posizionate',
          stickerPlacementHelp: 'Posiziona figurine',
          goToSection: 'Vai al capitolo',
          sectionHelp: 'Aiuto sui capitoli',
          addSection: 'Aggiungi capitolo',
          sectionDeleteWarning:
            'Se elimini il capitolo, verranno eliminati anche tutte le figurine e le pagine associate.',
          stickerDeleteWarning:
            "Se elimini la figurina, verrà eliminata definitivamente anche l'immagine associata. (Questo vale anche per le immagini raccolte tramite il link figurine.)",
          defaultStickerName: 'Nuova figurina',
        },
        stickerlink: {
          status: 'Stato',
          headline: '%{count} figurine raccolte',
          sublineActive: 'Il tuo link figurine è attivo.',
          sublineInactive: 'Il tuo link figurine è inattivo.',
          yourLink: 'Il tuo link figurine',
          description:
            'Con il link figurine puoi raccogliere facilmente e velocemente le foto di tutte le persone! Basta copiare e inviare.',
          copyLink: 'Copia link negli appunti',
          isActive: 'Link figurine attivo',
          isActiveHint: 'Attiva/disattiva caricamento foto',
          title: 'Richiedi titolo',
          titleHint: 'Mostra un campo per richiedere il titolo della figurina',
          surpriseMode: 'Modalità sorpresa',
          surpriseModeHint:
            'Rimuove tutti i riferimenti a Stickerstars e al prodotto',
          anonymousUrl: 'URL anonimo',
          message: 'Messaggio personale',
          viewLink: 'Visualizza link',
        },
        images: {
          ownImages: 'Immagini personali',
          stockImages: 'Libreria',
          imagesPlural: 'Immagini',
          help: 'Aiuto sugli sfondi',
          hint:
            'In questa sezione puoi caricare le foto di sfondo per il tuo album. Le foto per le tue figurine le crei e gestisci',
          stockHint:
            "Qui trovi una vasta selezione di immagini gratuite per il tuo album. Inserisci semplicemente una parola chiave o un tema nel campo di ricerca. Trascina un'immagine sull'area di disegno per inserirla nell'album.",
          maxImagesError:
            'Puoi caricare al massimo %{maxImages} immagini contemporaneamente.',
          deleteWarning:
            "Se elimini l'immagine di sfondo, verrà rimossa da tutte le pagine dell'album.",
          stockSearchPlaceholder: 'Cerca nella libreria...',
          stickerDropWarning:
            'Vuoi trasformare questa immagine di sfondo in una figurina? In alternativa, puoi caricare le immagini delle figurine anche nella sezione "Figurine".',
        },
        layouts: {
          hint:
            "Qui trovi una varietà di layout di pagina per il design del tuo album. Trascinali semplicemente su una pagina (vuota o esistente) nell'area di disegno per inserirli nell'album.",
          placeWarning:
            'Se applichi un layout a una pagina con contenuto, il contenuto andrà perso. Sei sicuro?',
        },
        styles: {
          headline: 'Personalizza stile',
          colors: 'Modifica stile colori',
          fonts: 'Modifica stile caratteri',
          stickers: 'Modifica design figurine',
          tryPresets: 'Prova i modelli',
          custom: 'Inserisci colori personalizzati',
          changeColor: 'Scegli un colore da modificare',
          primaryColor: 'Colore principale',
          secondaryColor: 'Colore secondario',
          tertiaryColor: 'Colore terziario',
          yourColors: 'Il tuo stile colori',
        },
        review: {
          noComments:
            'Non abbiamo suggerimenti per te, il tuo album è pronto per la stampa!',
          sendFeedback: 'Invia feedback',
          printApproval: 'Approvazione stampa',
          printApprovalTooltip:
            'Hai considerato tutti i commenti? Allora puoi darci qui il tuo OK per la stampa. Dopo non saranno più possibili modifiche',
        },
      },
      stockPanel: {
        defaultQuery: 'Trama',
      },
      stickerUploadsModal: {
        selectStickerHint: 'Seleziona una figurina per modificarla.',
        headline: 'Caricamenti (%{count})',
        submit: 'Aggiungi %{count} figurine',
      },
      stickerForm: {
        headline: 'Modifica figurina',
        namePlaceholder: 'Inserisci nome',
        positionPlaceholder: 'Inserisci posizione',
        doubleSticker: 'Figurina doppia',
        addImage: 'Aggiungi foto',
        changeImage: 'Cambia foto',
        downloadImage: 'Scarica foto',
        fitFace: 'Adatta viso',
        noFace: 'Nessun viso trovato',
        stickerLayout: 'Layout',
        stickerCell: 'Area adesiva',
        deleteWarning:
          "Se elimini la figurina, verrà eliminata definitivamente anche l'immagine associata. (Questo vale anche per le immagini raccolte tramite il link figurine.)",
      },
      albumTitle: {
        savedMessage: 'Ultimo salvataggio il %{date}',
        titlePlaceholder: "Titolo dell'album",
        releaseDate: "Data dell'evento",
        releaseDateTooltip:
          "Data dell'evento - quando deve essere pronto il tuo album?",
      },
      onboarding: {
        headline: 'Benvenuto',
        subline:
          'nel Designer! In questa pagina trovi tutto ciò che ti serve per iniziare. Se hai domande, non esitare a contattarci.',
        toAlbum: "Vai all'album",
        salesContactAlert:
          "Per un'organizzazione di queste dimensioni, avrai un referente dedicato; saremo lieti di occuparci di concetto, design e gestione del progetto per te. Prenota pure un appuntamento senza impegno nel mio calendario:",
        schedulerLink: 'Prenota appuntamento',
        usefulLinks: 'Link utili',
        tutorial: {
          headline: 'Leggi il tutorial',
          subline:
            'È tutto molto semplice: il nostro tutorial ti aiuterà a creare il tuo album.',
        },
        faq: {
          headline: 'Domande frequenti',
          subline:
            'Qui trovi le nostre risposte alle domande più frequenti sulla collezione.',
        },
        budget: {
          headline: 'Budget',
          subline:
            'Clicca qui per accedere al calcolatore dei prezzi e pianificare il tuo budget.',
        },
        firstSteps: 'Primi passi',
        getToKnowDesigner: 'Scopri il Designer',
        steps: {
          team: {
            headline: 'Invita il tuo team',
            text:
              'È più veloce insieme! Invita le persone che ti aiuteranno a creare il tuo album.',
          },
          albumTitle: {
            headline: "Personalizza il titolo e la data dell'evento",
            text: "Dai un nome al tuo album e imposta la data dell'evento.",
          },
          sticker: {
            headline: 'Crea la tua prima figurina',
            text:
              'Clicca qui per accedere alla gestione figurine - qui puoi creare le tue prime figurine.',
          },
          section: {
            headline: 'Personalizza i capitoli',
            text:
              'I capitoli strutturano il tuo album. Clicca qui per accedere alla gestione dei capitoli.',
          },
          stickerlink: {
            headline: 'Prepara il tuo link figurine',
            text:
              'Attraverso il link figurine, le tue star possono caricare le proprie foto per le figurine.',
          },
          image: {
            headline: "Posiziona un'immagine di sfondo",
            text:
              'Trascina una foto su una delle pagine per inserire uno sfondo.',
          },
        },
      },
    },
    stickerUploadForm: {
      nameLabel: 'Il tuo nome',
      namePlaceholder: 'Il tuo nome',
      dataProtection: 'Ci prendiamo cura dei tuoi dati.',
      dataProtectionLink: 'Qui',
      dataProtectionInfo: 'trovi le informazioni sulla privacy.',
      lastNameLabel: 'Il tuo cognome (facoltativo)',
      lastNamePlaceholder: 'Il tuo cognome',
      positionLabel: 'Titolo/Posizione (facoltativo)',
      positionPlaceholder: 'Il tuo titolo',
      photoLabel: 'La tua foto',
      changePhoto: 'modifica',
      uploadInstructions:
        "Scegli un'immagine dal computer o usa il tuo smartphone per scattarne una!",
      successHeading: 'Fatto 👍',
      successMessage:
        "Il tuo caricamento è andato a buon fine, grazie mille! Ora è il momento dell'attesa.",
      restartButton: 'ricomincia',
      sectionLabel: 'Il tuo capitolo',
      dontKnow: 'Non lo so',
      sectionHelp:
        'Se non sai cosa indicare qui, lascia semplicemente "Non lo so".',
      uploadingHeading: 'Caricamento in corso...',
      uploadingMessage:
        'Grazie per la tua partecipazione - stiamo caricando i dati.',
      progressLabel: 'Avanzamento',
      uploaderHeading: 'Benvenuto nel caricamento foto',
      backButton: 'indietro',
      nextButton: 'avanti',
      privacyLink: 'Privacy',
      imprintLink: 'Note legali',
      title: 'Stickerstars | Caricamento foto',
      titleWhiteLabel: 'Caricamento foto',
      loading: 'Caricamento...',
      albumError:
        'Questo album non esiste o al momento non accetta caricamenti.',
    },
    stickerTemplate: {
      name: 'Lisa Allegri',
      position: 'Sorella della sposa',
    },
    profile: {
      headline: 'Il mio profilo',
      subline: 'Informazioni sul tuo account utente',
      ordersHeadline: 'I miei ordini',
      showDetails: 'Mostra dettagli',
    },
    showOrder: {
      headline: 'Dettagli ordine',
      subline: 'Qui trovi tutte le informazioni sul tuo ordine.',
      details: 'Dettagli',
      shipping: 'Spedizione',
      timeline: 'Timeline',
    },
    checkout: {
      backToAlbum: "torna all'album",
      title: 'Il tuo ordine',
      errorMessage: 'Ops, qualcosa è andato storto. Riprova per favore.',
      personalInfoMessage:
        'Perfetto, ora ci servono solo alcune informazioni personali - poi hai finito.',
      previewConfirmation: 'Tutto come te lo immaginavi?',
      firstNameRequired: 'Il nome è obbligatorio',
      lastNameRequired: 'Il cognome è obbligatorio',
      emailInvalid: 'Email non valida',
      addressRequired: "L'indirizzo è obbligatorio",
      zipRequired: 'Il CAP è obbligatorio',
      cityRequired: 'La città è obbligatoria',
      countryRequired: 'Il paese è obbligatorio',
      phoneNumberFormat:
        'Il numero di telefono può contenere solo numeri e spazi',
      phoneNumberLength:
        'Il numero di telefono può essere lungo max 20 caratteri',
      commentLength: 'Il commento può essere lungo max 500 caratteri',
      companyRequired: "L'azienda è obbligatoria",
      clubRequired: "L'associazione è obbligatoria",
      printConsent:
        'Per il processo di produzione, autorizzo Stickerstars ad accedere al mio album.',
      orderWithCost: 'Ordina con obbligo di pagamento',
      backToOrderDetails: "Torna ai dettagli dell'ordine",
      orderConfirmation: 'Grazie, il tuo ordine è in elaborazione.',
      nextStepPackageContents: 'Prossimo passo: Contenuto del pacchetto',
      eventDateRequired: "La data dell'evento è obbligatoria",
      nextStepYourData: 'Prossimo passo: I tuoi dati',
      backToAlbumPreview: "Torna all'anteprima album",
      contactHeading: 'Contatto',
      email: 'Email',
      deliveryBillingAddress: 'Indirizzo di consegna/fatturazione',
      firstName: 'Nome',
      lastName: 'Cognome',
      streetAndNumber: 'Via e numero civico',
      addressAddition: 'Informazioni aggiuntive',
      optional: 'facoltativo',
      zipCode: 'CAP',
      city: 'Città',
      country: 'Paese',
      phone: 'Telefono',
      quoteNumber: 'Numero preventivo',
      remarks: 'Note',
      commentPlaceholder: "Hai qualcos'altro da dirci? Scrivici.",
      albumsLabel: 'Scegli il numero di album',
      albumsNeeded: 'Mi servono <b>%{count} album</b>',
      chooseAdditionalOptions: 'Scegli tra le nostre opzioni aggiuntive',
      eventLabel: 'Quando si terrà il tuo evento?',
      orderSummary: 'Riepilogo ordine',
      priceIncludingShipping: 'Prezzo spedizione inclusa',
      vat: 'IVA',
      totalCost: 'Costo totale',
      estimatedDeliveryDate: 'Data di consegna prevista: <b>%{date}</b>',
      deliveryWarning:
        "Potrebbe essere un po' stretto con i tempi. Contattaci prima di effettuare l'ordine.",
      voucherInvalid: 'Questo codice non è valido',
      voucherInactive: 'Questo codice non è più attivo',
      voucherTaken: 'Questo codice è già stato utilizzato',
      voucherApplied: 'Il tuo buono sconto %{code} è stato applicato! 🎉',
      haveVoucher: 'Hai un buono sconto?',
      voucherCodePlaceholder: 'Codice buono sconto',
      organizationLabel: 'Organizzazione',
      countryLabels: {
        DE: 'Germania',
        CH: 'Svizzera',
        AT: 'Austria',
        LU: 'Lussemburgo',
        FR: 'Francia',
        NL: 'Paesi Bassi',
        BE: 'Belgio',
        IE: 'Irlanda',
        FI: 'Finlandia',
        IT: 'Italia',
        PL: 'Polonia',
      },
      stickerPackagingOptionsLabel: 'In che formato preferisci i tuoi sticker?',
      stickerPackagingOptions: {
        stickerpack: {
          title: 'Pacchetti Sorpresa',
          text:
            "Il formato classico: 4 sticker assortiti in ogni pacchetto, per il piacere della scoperta - ideale per creare un'atmosfera coinvolgente dove gli ospiti possono scambiarsi i doppioni e completare insieme la collezione.",
        },
        stickerboxSingleVariety: {
          title: 'Box Personalizzati',
          text:
            'Il formato business: ogni box contiene sticker tutti uguali dello stesso soggetto. La soluzione perfetta per chi vuole distribuire agli ospiti più copie del proprio sticker personale.',
        },
      },
    },
    settingsModal: {
      title: 'Impostazioni',
      generalTab: 'Generale',
      usersTab: 'Gestisci utenti',
      emailNotifications: 'Notifiche email',
      emailNotificationsHelp:
        'Ti invieremo una notifica, ad esempio, quando nuove figurine vengono caricate nel tuo album tramite il link figurine.',
      albumPreview: 'Anteprima album',
      albumPreviewHelp:
        "Attiva l'anteprima album per generare un link condivisibile. Il link permette solo la visualizzazione, non la modifica. Attenzione: con questo link chiunque può vedere il tuo album. Condividilo solo con persone di cui ti fidi.",
      saveError: 'Errore durante il salvataggio',
      language: "Lingua dell'album",
      languageHelp:
        "La lingua dell'album controlla la visualizzazione di dati e numeri.",
      users: {
        error: 'Operazione non riuscita. Riprova più tardi.',
        inviteUser: 'Invita utente',
        emailPlaceholder: 'Indirizzo email',
        sendInvitation: 'Invia invito',
        cancel: 'Annulla',
        usersHeader: 'Utenti',
        ownerBadge: 'Proprietario',
        removeUser: 'Rimuovi',
        invitationsHeader: 'Inviti in sospeso',
        cancelInvitation: 'Annulla',
        noInvitations: 'Nessun invito in sospeso.',
        invitationsError: 'Si è verificato un errore.',
      },
    },
    notifications: {
      previewMode:
        'Il tuo album è in modalità anteprima, al momento non sono possibili modifiche.',
      autoSave: 'Le tue modifiche vengono salvate automaticamente.',
    },
    invitations: {
      error: 'Operazione non riuscita. Riprova più tardi.',
      joinMessage:
        '<b>%{inviterEmail}</b> ti ha invitato a unirti al progetto figurine <b>%{albumTitle}</b>. Per continuare, clicca sul pulsante e accedi o crea un account:',
      acceptInvitation: 'Accetta invito',
      accessInfo:
        "Dopo aver effettuato l'accesso, avrai accesso all'album e alle figurine e potrai iniziare a modificare.",
      moreInfo:
        'Qui trovi ulteriori informazioni su STICKERSTARS e il vostro progetto.',
      companyInfo:
        'STICKERSTARS Technologies GmbH, Wilhelm-Kabus-Str. 42/44, 10829 Berlino, Germania',
    },
    models: {
      title: 'Titolo',
      createdAt: 'Creato il',
      album: {
        one: 'Album',
        plural: 'Album',
        releaseDate: "Data dell'evento",
      },
      order: {
        id: 'Numero ordine',
        lineItems: 'Articoli',
        net: 'Netto',
        vat: 'IVA',
        deliveryNote: 'Bolla di consegna',
        invoice: 'Fattura',
        comment: 'Commento',
      },
      lineItem: {
        quantity: 'Quantità',
      },
    },
    cookies: {
      title: '🍪 Cookie e Privacy',
      description:
        'Per offrirti la migliore esperienza di navigazione possibile, vorremmo salvare alcuni cookie! Trovi maggiori informazioni nella nostra <a target="__blank" aria-label="Link alla pagina della privacy" class="cc-link" href="https://stickerstars.de/datenschutz">Informativa sulla privacy</a>. Clicca su "Accetto" per accettare i cookie e visitare il nostro sito!',
      acceptAll: 'Accetto',
      settings: 'Impostazioni',
      settingsModal: {
        title: 'Impostazioni cookie',
        saveSettings: 'Salva impostazioni',
        acceptAll: 'Accetta tutti',
        rejectAll: 'Rifiuta tutti',
        close: 'chiudi',
        intro:
          'Utilizziamo i cookie per garantire le funzionalità di base del sito e per migliorare la tua esperienza online. Puoi scegliere per ogni categoria se accettare o meno questi cookie.',
        necessary: {
          title: 'Cookie necessari',
          description:
            'I cookie necessari (e tecnologie simili) sono indispensabili per il corretto funzionamento del sito e non possono essere disattivati.',
        },
        analytics: {
          title: 'Cookie per analisi e marketing',
          description:
            'Questi cookie raccolgono informazioni su come utilizzi il sito, quali pagine visiti e quali link hai cliccato. Tutti i dati sono anonimi e non possono essere utilizzati per identificarti.',
        },
        moreInfo: {
          title: 'Ulteriori informazioni',
          description:
            'Per ulteriori informazioni sulla nostra politica dei cookie e sulla privacy, visita la nostra pagina sulla <a class="cc-link" target="_BLANK" href="https://www.stickerstars.de/datenschutz">Privacy</a>.',
        },
      },
    },
    footer: {
      imprint: 'Note legali',
      privacy: 'Privacy',
    },
  },
  business: {
    editor: {
      stockPanel: {
        defaultQuery: 'Lavoro di squadra',
      },
    },
    stickerTemplate: {
      name: 'Alessandra Alberti',
      position: 'CFO',
    },
    checkout: {
      organizationLabel: 'Azienda',
    },
  },
  sports: {
    editor: {
      stockPanel: {
        defaultQuery: 'Sport',
      },
      stickerTemplate: {
        name: 'Giulia Giusti',
        position: 'Centrocampista destra',
      },
    },
    checkout: {
      organizationLabel: 'Associazione / Organizzazione',
    },
  },
};
