export const dimensions = {
  pageWidth: 230,
  pageHeight: 297,
  pagePadding: 60,
  pageBleed: 2,
  cropSave: 4,
  pageSlug: 10,
  workspacePaddingX: 460,
  stickerWidth: 50,
  stickerHeight: 70,
  stickerBleed: 2,
  gridSize: 5,
  spreadHighlightSize: 5,
  dangerZoneWidth: 16,
};

export const itemTypes = {
  element: 'element',
  section: 'section',
  sticker: 'sticker',
  image: 'image',
  stock: 'stock',
  spread: 'spread',
};

export const alignDirection = {
  start: 'start',
  center: 'center',
  end: 'end',
};

export const alignTypes = {
  page: 'page',
  spread: 'spread',
  selection: 'selection',
};

// These fields need to match existing album JSON, so they can not be camelized
export const symbolTypes = {
  section: 'Name des Kapitels',
  album: 'Titel des Albums',
  release_date: 'Tag des Events',
  pagenum: 'Seitenzahl',
  totalpages: 'Gesamtseitenzahl',
  totalstickers: 'Gesamtstickeranzahl',
  toc_sections: 'Inhaltsverzeichnis / Kapitel',
  toc_pages: 'Inhaltsverzeichnis / Seiten',
};

export const renderModes = {
  album_softcover: 'Album Softcover',
  album_softcover_inside: 'Album Softcover Inside',
  stickers: 'Stickers',
  stickerpack: 'Stickerpack',
  spread: 'Album-Doppelseite',
};

export const renderFormats = {
  pdf: 'PDF',
  jpg: 'JPGs',
  png: 'PNGs',
};

export const colorValues = {
  selection: '#004C97',
  selectionInside: '#88d14c',
  spreadInStickerMode: '#333',
  spread: '#fff',
  background: '#F5F5F5',
  backgroundIsolation: '#a1f6ff',
  guideSpread: '#ff6fa1',
};

export const resolutions = {
  auto: 'auto',
  small: 'small',
  medium: 'medium',
  large: 'large',
  full: 'full',
  original: 'original',
};

export const keys = {
  space: ' ',
  left: 'ArrowLeft',
  right: 'ArrowRight',
  up: 'ArrowUp',
  down: 'ArrowDown',
};

export const swatchTypeIndexes = {
  UNSELECTED: -3,
  CUSTOM: -2,
  NONE: -1,
};

export const blendModes = [
  // supported by prince:
  'normal',
  'multiply',
  'screen',
  'darken',
  'lighten',

  // custom
  'luminosity',

  // not supported by prince:
  // 'overlay',
  // 'color-dodge',
  // 'color-burn',
  // 'hard-light',
  // 'soft-light',
  // 'difference',
  // 'exclusion',
  // 'hue',
  // 'saturation',
  // 'color',
  // 'luminosity',
];

export const operations = {
  rotate: 'rotateOperation',
  resize: 'resizeOperation',
  scale: 'scaleOperation',
};

/**
 * These `actions` allows to reference certain redux-actions by string. They
 * are also used in the `actionHotkeys`-constant. All actions have in common
 * that they don't expect any parameter but instead get their targeted items
 * from the `selection` (via `getState`). These actions can be executed
 * using `executeActionByName`.
 */
export const actions = {
  createSpreadBefore: 'createSpreadBefore',
  createSpreadAfter: 'createSpreadAfter',
  moveSpreadItemsUp: 'moveSpreadItemsUp',
  moveSpreadItemsDown: 'moveSpreadItemsDown',
  deleteSpreadItems: 'deleteSpreadItems',
  duplicateItems: 'duplicateItems',
  sendItemsFront: 'sendItemsFront',
  sendItemsBack: 'sendItemsBack',
  deleteElementItems: 'deleteElementItems',
  groupItems: 'groupItems',
  ungroupItems: 'ungroupItems',
  fitContentCover: 'fitContentCover',
  fitFrame: 'fitFrame',
  flipImage: 'flipImage',
  historyUndo: 'historyUndo',
  historyRedo: 'historyRedo',
  selectAll: 'selectAll',
  toggleEnableFilter: 'toggleEnableFilter',
  toggleCropPreview: 'toggleCropPreview',
  toggleGridEnabled: 'toggleGridEnabled',
  toggleAutoFit: 'toggleAutoFit',
  toggleShowElementsTree: 'toggleShowElementsTree',
  resetStickerId: 'resetStickerId',
  uploadWorkspaceImage: 'uploadWorkspaceImage',
  editText: 'editText',
  exitEditText: 'exitEditText',
  editImage: 'editImage',
  exitEditImage: 'exitEditImage',
};

/**
 * In some cases, we need to translate an (allowed) ActionButton component to its associated
 * workspace action (dispatched via `executeActionByName`). This is an inexhaustive
 * mapping from components to actions.
 */
export const actionsByActionButton = {
  DeleteButton: [actions.deleteElementItems],
  DuplicateButton: [actions.duplicateItems],
};

/**
 * Returns an array of arrays, each element representing an action:
 *   [0] action to be called
 *   [1] hotkeys as array of objects
 */
export const actionHotkeys = [
  [actions.deleteElementItems, [{ key: 'Delete' }, { key: 'Backspace' }]],
  [actions.duplicateItems, [{ key: 'd', ctrlKey: true }]],
  [actions.groupItems, [{ key: 'g', ctrlKey: true, shiftKey: false }]],
  [
    actions.ungroupItems,
    [
      { key: 'G', ctrlKey: true, shiftKey: true },
      { key: 'u', ctrlKey: true },
    ],
  ],
  [actions.historyUndo, [{ key: 'z', ctrlKey: true }]],
  [actions.historyRedo, [{ key: 'Z', ctrlKey: true, shiftKey: true }]],
  [actions.selectAll, [{ key: 'a', ctrlKey: true }]],
];

export const fontSwatches = {
  Title: {
    label: 'editor.toolbar.title',
    symbol: 'title',
  },
  Subtitle: {
    label: 'editor.toolbar.subline',
    symbol: 'sub_title',
  },
  Paragraph: {
    label: 'editor.toolbar.text',
    symbol: 'Paragraph',
  },
};

export const fonts = [
  'Alegreya',
  'Angelface',
  'Archivo',
  'Arimo',
  'Arvo',
  'B612',
  'Belleza',
  'BioRhyme',
  'Cairo',
  'Cardo',
  'Concert One',
  'Cormorant',
  'Crimson Text',
  'Dinot',
  'Fira Sans',
  'Fjalla One',
  'FK CARIAD Light',
  'Frank Ruhl Libre',
  'Gill Sans',
  'Great Vibes',
  'Karla',
  'Krona One',
  'Lato',
  'Lora',
  'Merriweather',
  'Milesian Circa',
  'Montserrat',
  'Mulish',
  'Noto Sans',
  'Nunito',
  'Old Standard TT',
  'Open Sans',
  'Oswald',
  'Oxygen',
  'Parisienne',
  'Pinyon Script',
  'Playfair Display',
  'Poppins',
  'PT Sans',
  'PT Serif',
  'Rakkas',
  'Roboto',
  'Rubik',
  'Source Sans Pro',
  'Space Grotesk',
  'Spectral',
  'Syne',
  'Tangerine',
  'Tinos',
  'Titillium Web',
  'Ubuntu',
  'Varela',
  'Vollkorn',
  'Work Sans',
  'Yatra One',
];

export const defaultFontWeights = [400, 700];

export const customFontWeights = {
  Rubik: [300, 400, 500, 700, 900],
  Dinot: [300, 400, 600],
  // only 400 loadable
  Angelface: [400],
  Belleza: [400],
  'Concert One': [400],
  'Fjalla One': [400],
  'FK CARIAD Light': [400],
  'Great Vibes': [400],
  'Krona One': [400],
  'Milesian Circa': [400],
  Parisienne: [400],
  'Pinyon Script': [400],
  'PT Sans': [400],
  Rakkas: [400],
  Varela: [400],
  'Yatra One': [400],
};

const fontVariantDescriptionMap = {
  300: 'n3',
  400: 'n4',
  600: 'n6',
  700: 'n7',
  900: 'n9',
};

/** Fetch the font weights we want */
const fontWeights = font => customFontWeights[font] || defaultFontWeights;

// Ex. return: 'Rubik' -> 'family=Rubik:wght@300;400;700;900'
const toWebFontParam = font =>
  `family=${font}:wght@${fontWeights(font).join(';')}`;

// Ex. return: 'Rubik' -> 'Rubik:n3,n4,n7,n9'
const toFVD = font =>
  `${font}:${fontWeights(font)
    .map(weight => fontVariantDescriptionMap[weight])
    .join(',')}`;

const webFontParams = fonts.map(toWebFontParam).join('&');
export const webFontFamilies = fonts.map(toFVD);
export const webFontsUrl = `https://fonts.stickerstars.de/css?&${webFontParams}&display=block`;

export const defaultFontStyles = {
  fontFamily: 'Rubik',
  lightWeight: '300',
  regularWeight: '400',
  mediumWeight: '500',
  boldWeight: '700',
  blackWeight: '900',
};

export const colorCombinations = [
  ['#000000', '#FFFFFF', '#F8B195', '#C06C84', '#355C7D'],
  ['#000000', '#FFFFFF', '#99B898', '#FECEAB', '#E84A5F'],
  ['#000000', '#FFFFFF', '#571032', '#D1F3DE', '#B07289'],
  ['#000000', '#FFFFFF', '#B8D8D8', '#4F6367', '#EEF5DB'],
  ['#000000', '#FFFFFF', '#173F5F', '#20639B', '#F6D55C'],
  ['#000000', '#FFFFFF', '#DCEDC2', '#FFD3B5', '#FF8C94'],
  ['#000000', '#FFFFFF', '#E3F09B', '#87B6A7', '#5B5941'],
  ['#000000', '#FFFFFF', '#FCBB6D', '#D8737F', '#AB6C82'],
];

export const fontCombinations = [
  { title: 'Merriweather', sub_title: 'Merriweather', paragraph: 'Oswald' },
  { title: 'Poppins', sub_title: 'Arvo', paragraph: 'Open Sans' },
  { title: 'Montserrat', sub_title: 'Montserrat', paragraph: 'Merriweather' },
  { title: 'Montserrat', sub_title: 'Montserrat', paragraph: 'Oswald' },
  {
    title: 'Playfair Display',
    sub_title: 'Playfair Display',
    paragraph: 'Source Sans Pro',
  },
  { title: 'Alegreya', sub_title: 'Alegreya', paragraph: 'Lato' },
  { title: 'Fjalla One', sub_title: 'Fjalla One', paragraph: 'Noto Sans' },
  { title: 'Merriweather', sub_title: 'Merriweather', paragraph: 'Montserrat' },
  { title: 'Lora', sub_title: 'Lora', paragraph: 'Merriweather' },
  { title: 'Rubik', sub_title: 'Rubik', paragraph: 'Karla' },
];

export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const API_URL = `${API_BASE_URL}/v1`;
export const UPLOADS_URL = `${API_BASE_URL}/rails/active_storage/direct_uploads`;

export const MULTIPLAYER_URL = process.env.REACT_APP_MULTIPLAYER_URL;

export const maxImageUploads = 15;

export const stickerUploadsPollingInterval = 30000;

export const helpscoutClientId =
  process.env.NODE_ENV !== 'test'
    ? '71d3000e-ea32-4c49-85fc-461d89c9c85e'
    : null;

// Windows smaller than this width will show a blocking message
export const minimumSupportedWindowWidth = 1000;

export const legalPermalinks = {
  agb: 'https://www.stickerstars.de/designer/agb',
  info: 'https://www.stickerstars.de/designer/kundeninformationen',
};

export const stickerLinkCustomDomains = [
  'bilder-einsammeln.de',
  'fotouploader.de',
  'sstrs.de',
];

export const validStickerKeys = [
  'name',
  'position',
  'doubleSticker',
  'cx',
  'cy',
  'cscale',
  'crotation',
  'id',
  'image',
  'sectionId',
];

export const elementSortTiers = {
  Circle: 0,
  Group: 0,
  Image: 0,
  Line: 0,
  Rectangle: 0,
  StickerCell: 1,
  GroupOfText: 2,
  ElementAlwaysOnTop: 3,
  Text: 4,
  Comment: 5,
};

export const elementTypesWithDangerZone = ['Text', 'StickerCell'];

export const fontSizeScaleFactor = 0.1;

export const pointToMillimeterFactor = 1 / 2.835;

export const countryOptions = [
  { value: 'DE', label: 'checkout.countryLabels.DE' },
  { value: 'CH', label: 'checkout.countryLabels.CH' },
  { value: 'AT', label: 'checkout.countryLabels.AT' },
  { value: 'LU', label: 'checkout.countryLabels.LU' },
  { value: 'FR', label: 'checkout.countryLabels.FR' },
  { value: 'NL', label: 'checkout.countryLabels.NL' },
  { value: 'BE', label: 'checkout.countryLabels.BE' },
  { value: 'IE', label: 'checkout.countryLabels.IE' },
  { value: 'FI', label: 'checkout.countryLabels.FI' },
  { value: 'IT', label: 'checkout.countryLabels.IT' },
  { value: 'PL', label: 'checkout.countryLabels.PL' },
];

export const spineOffset = 12; // defined by Esveco

export const defaultStickerTemplateId = 'default';

export const stickerTemplateIds = [
  'default',
  'gold',
  'accented',
  'themed',
  'triangle',
  'tilted',
];

export const wizardMobileSplideOptions = {
  rewind: true,
  arrows: false,
  pagination: false,
  gap: '0.375rem',
};

export const jobStateStyles = {
  queued: { icon: 'hourglass', variant: 'secondary' },
  working: { icon: 'arrowRepeat', variant: 'warning' },
  exists: { icon: 'download', variant: 'success' },
  finished: { icon: 'checkmark', variant: 'success' },
  error: { icon: 'ban', variant: 'danger' },
};

export const salesContact = {
  organizationSizeThreshold: 200,
  schedulerLink:
    'https://business.stickerstars.de/scheduler/expertengesprach-stickerstars',
  avatarLink: 'https://business.stickerstars.de/scheduler/avatar',
};

export const languages = ['de', 'en', 'fr', 'it'];
