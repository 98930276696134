import { symbols } from '../useAppEnvironment';

export default {
  default: {
    languages: {
      de: 'Niemiecki',
      en: 'Angielski',
      fr: 'Francuski',
      it: 'Włoski',
      pl: 'Polski',
    },
    rename: 'Zmień nazwę',
    upload: 'Wgraj',
    delete: 'Usuń',
    duplicate: 'Duplikuj',
    moveUp: 'Przenieś wyżej',
    moveDown: 'Przenieś niżej',
    stickersPlural: 'Naklejki',
    save: 'Zapisz',
    cancel: 'Anuluj',
    learnMore: 'Dowiedz się więcej',
    settings: 'Ustawienia',
    search: 'Szukaj',
    here: 'tutaj',
    undo: 'Cofnij',
    redo: 'Ponów',
    on: 'włączone',
    off: 'wyłączone',
    help: 'Pomoc',
    pagination: 'Strona %{page} z %{total}',
    genericSuccess: 'Pomyślnie zapisano.',
    genericError: 'Wystąpił błąd. Spróbuj ponownie.',
    [symbols.business]: 'Biznes',
    [symbols.wedding]: 'Ślub',
    [symbols.sports]: 'Sport',
    [symbols.birthday]: 'Urodziny',
    [symbols.school]: 'Szkoła',
    [symbols.community]: 'Społeczność',
    selectOption: 'Wybierz opcję',
    back: 'Wstecz',
    next: 'Dalej',
    createAlbum: 'Utwórz album',
    errorBoundary:
      'Coś poszło nie tak z ostatnią akcją. Nasz zespół został powiadomiony. Twój ostatni stan został zapisany.',
    render: {
      stickerpackText: 'Otwórz tutaj',
    },
    start: {
      headline: 'Moje Albumy',
      subline: 'Tutaj znajdziesz utworzone przez siebie albumy.',
      accountActivatedNotification: 'Twoje konto zostało pomyślnie aktywowane.',
    },
    wizard: {
      autosaveModal: {
        headline: 'Miło Cię znowu widzieć!',
        text:
          'Znaleźliśmy poprzedni stan konfiguracji. Czy chcesz go wczytać, czy zacząć od nowa?',
        confirm: 'Tak, proszę wczytać',
        decline: 'Zacznij od nowa',
      },
      pageHint: 'Użyj przycisków, aby poruszać się po podglądzie!',
      tabs: {
        blueprints: {
          headline: 'Wybierz szablon',
          content:
            'Nasi projektanci stworzyli dla Ciebie świetne szablony. Wybierz ten, który Ci odpowiada i dostosuj go do swoich preferencji w następnym kroku.',
        },
        data: {
          headline: 'Prawie gotowe!',
          content: 'Jeszcze tylko kilka szczegółów i możemy zaczynać!',
          organizationName: 'Nazwa firmy',
          numEmployees: 'Liczba pracowników',
          contactName: 'Twoje imię',
          phone: 'Numer telefonu',
          phoneDescription:
            'Możemy zaoferować najlepszą obsługę przez telefon.',
          contactRole: 'Stanowisko',
          contactRoleDescription: 'Co najlepiej opisuje Twoją rolę w firmie?',
          contactRoles: {
            member: 'Członek zespołu',
            lead: 'Team Leader / Project Manager',
            senior: 'Kierownictwo wyższego szczebla',
            executive: 'Zarząd',
          },
        },
      },
    },
    confirmModal: {
      defaultHeadline: 'Czy jesteś pewny?',
      defaultConfirm: 'Jestem pewny',
    },
    editor: {
      autosave: {
        connectionError:
          'Błąd połączenia z serwerem, Twoje zmiany mogą nie zostać zapisane.',
        mergeError:
          'Nie udało się zapisać Twojego albumu, będziemy próbować dalej.',
      },
      meta: {
        orderNow: 'Zamów teraz',
        priceLabel: 'od 1 241,00 zł',
        currentSpread: 'Aktualna strona',
        allSpreads: 'Wszystkie strony',
        grid: 'Siatka',
        elements: 'Elementy strony',
        showBleed: 'Pokaż format końcowy',
        terms: 'Regulamin',
        customerInformation: 'Informacje o kliencie',
        privacy: 'Prywatność',
        contact: 'Kontakt',
        mobileAlert:
          'Łatwiej na laptopie! Przełącz się na urządzenie z większym ekranem, aby zaprojektować swój album.',
        mobileCta: 'Zmień urządzenie',
        mobileModalHeadline: 'Kontynuuj tutaj',
        mobileModalContent:
          'Kliknij przycisk – wyślemy Ci link e-mailem, żebyś mógł kontynuować edycję albumu na laptopie.',
        mobileModalCta: 'Wyślij link',
        mobileModalConfirmation: 'Gotowe, sprawdź swoją skrzynkę!',
        profile: 'Profil',
        logout: 'Wyloguj',
        saveError: 'Wystąpił problem z zapisaniem Twojego albumu',
        stickerCellDropError:
          'Tylko jeden obraz może być dodany do każdego pola naklejki.',
        printPreview: {
          preview: 'Podgląd wydruku',
          turnOn: 'Włącz podgląd wydruku',
          turnOff: 'Wyłącz podgląd wydruku',
        },
      },
      imageUpload: {
        maxImagesError: 'Można przesłać maksymalnie %{max} zdjęć jednocześnie.',
        uploadError: 'Wystąpił problem z przesłaniem %{fileName}.',
        sizeError: 'Obraz musi mieć rozmiar między %{min} a %{max} mb.',
        typeError: 'Obraz musi być w formacie %{types}.',
        dimensionsError:
          'Obraz może mieć maks. %{maxDimension}x%{maxDimension} pikseli.',
      },
      spreadMeta: {
        moveUp: 'Przenieś stronę wyżej',
        moveDown: 'Przenieś stronę niżej',
        duplicate: 'Duplikuj stronę',
        delete: 'Usuń stronę',
        deleteConfirmation:
          'Gdy usuniesz rozkładówkę, wszystkie umieszczone na niej elementy również zostaną usunięte (tekst, pola naklejek, kształty itp.)',
        pageRange: 'Strony %{start} - %{end}',
        addSpread: 'Wstaw pustą stronę',
      },
      toolbar: {
        fill: 'Wypełnienie',
        strokeColor: 'Kolor obramowania',
        strokeWidth: 'Szerokość obramowania',
        opacity: 'Przezroczystość',
        fontFamily: 'Czcionka',
        fontSize: 'Rozmiar czcionki',
        textColor: 'Kolor tekstu',
        lineHeightAndAlign: 'Wysokość linii i wyrównanie',
        alignLeft: 'Wyrównaj do lewej',
        alignCenter: 'Wyśrodkuj',
        alignRight: 'Wyrównaj do prawej',
        alignJustify: 'Wyjustuj',
        lineHeight: 'Wysokość linii',
        symbolText: 'Tekst automatyczny',
        group: 'Grupuj',
        ungroup: 'Rozgrupuj',
        finishEditing: 'Zakończ edycję',
        editImage: 'Edytuj obraz',
        uploadImage: 'Wgraj obraz',
        flip: 'Odbij',
        toFront: 'Przenieś na wierzch',
        toBack: 'Przenieś na spód',
        editSticker: 'Edytuj naklejkę',
        uploadStickerImage: 'Wgraj obraz naklejki',
        editText: 'Edytuj tekst',
        showLess: 'mniej...',
        showMore: 'więcej...',
        title: 'Nagłówek',
        subline: 'Podtytuł',
        text: 'Tekst',
        bold: 'Pogrubienie',
        italic: 'Kursywa',
        uppercase: 'Wielkie litery',
        fontSizeAutoFitHint:
          'Rozmiar czcionki może zostać zmniejszony, aby dopasować tekst w jednej linii',
        autoFit: 'Autodopasowanie',
        automaticText: 'Tekst automatyczny',
        freeText: '(Dowolny tekst)',
        section: 'Nazwa rozdziału',
        album: 'Tytuł albumu',
        release_date: 'Data wydarzenia',
        pagenum: 'Numer strony',
        totalpages: 'Całkowita liczba stron',
        totalstickers: 'Całkowita liczba naklejek',
        toc_sections: 'Spis treści / Rozdziały',
        toc_pages: 'Spis treści / Strony',
        unlinkSticker: 'Odłącz naklejkę',
        newText: 'Nowy tekst...',
        symbolNote: 'To jest tekst automatyczny, którego nie można edytować.',
      },

      elements: {
        text: 'Pole tekstowe',
        circle: 'Koło',
        rectangle: 'Prostokąt',
        line: 'Linia',
        stickerCell: 'Obszar naklejki',
        comment: 'Komentarz',
      },
      albumTitle: {
        savedMessage: 'Ostatnio zapisano: %{date}',
        titlePlaceholder: 'Tytuł albumu',
        releaseDate: 'Data wydarzenia',
        releaseDateTooltip:
          'Data wydarzenia – kiedy album powinien być gotowy?',
      },
      onboarding: {
        headline: 'Witaj',
        subline:
          'w Designerze! Na tej stronie znajdziesz wszystko, czego potrzebujesz na początek. Jeśli masz pytania, możesz się z nami skontaktować w każdej chwili.',
        toAlbum: 'Do albumu',
        salesContactAlert:
          'Przy organizacji tej wielkości masz dedykowanego opiekuna; chętnie zajmiemy się dla Ciebie koncepcją, projektem i zarządzaniem projektem. Zaplanuj niezobowiązującą rozmowę w moim kalendarzu:',
        schedulerLink: 'Umów spotkanie',
        usefulLinks: 'Przydatne linki',
        tutorial: {
          headline: 'Przeczytaj samouczek',
          subline:
            'To wszystko proste: Nasz samouczek pomoże Ci stworzyć album.',
        },
        faq: {
          headline: 'Często zadawane pytania',
          subline:
            'Tutaj znajdziesz nasze odpowiedzi na najczęstsze pytania dotyczące zbierania.',
        },
        budget: {
          headline: 'Budżet',
          subline:
            'Kliknij tutaj, aby przejść do kalkulatora cen i zaplanować swój budżet.',
        },
        firstSteps: 'Pierwsze kroki',
        getToKnowDesigner: 'Poznaj Designer',
        steps: {
          team: {
            headline: 'Zaproś swój zespół',
            text:
              'Tak będzie szybciej! Zaproś osoby, które pomogą Ci stworzyć album.',
          },
          albumTitle: {
            headline: 'Dostosuj tytuł i datę wydarzenia',
            text: 'Nadaj swojemu albumowi nazwę i ustaw datę wydarzenia.',
          },
          sticker: {
            headline: 'Stwórz swoją pierwszą naklejkę',
            text:
              'Kliknij tutaj, aby przejść do zarządzania naklejkami – tam możesz stworzyć swoją pierwszą naklejkę.',
          },
          section: {
            headline: 'Dostosuj rozdziały',
            text:
              'Rozdziały strukturyzują Twój album. Kliknij tutaj, aby przejść do zarządzania rozdziałami.',
          },
          stickerlink: {
            headline: 'Przygotuj link do naklejek',
            text:
              'Przez link do naklejek Twoje gwiazdy naklejek mogą wgrywać swoje zdjęcia do naklejek.',
          },
          image: {
            headline: 'Umieść zdjęcie w tle',
            text:
              'Przeciągnij zdjęcie na jedną ze stron, aby umieścić zdjęcie w tle.',
          },
        },
      },
      sidebar: {
        tabs: {
          onboarding: 'Start',
          stickers: 'Naklejki',
          stickerlink: 'Link do naklejek',
          background: 'Tło',
          layouts: 'Układy',
          styles: 'Design',
          jobs: 'Eksport',
          review: 'Opinie',
        },

        stickers: {
          expand: 'Rozwiń rozdział',
          collapse: 'Zwiń rozdział',
          search: 'Szukaj naklejek',
          newStickers: 'Nowe naklejki',
          newStickersNotification:
            '<b>%{count} nowych naklejek</b> zostało wgranych przez Twój link do naklejek.',
          collectStickers: 'Zbierz naklejki',
          unplacedStickers: 'Nieumieszczone naklejki',
          stickerPlacementHelp: 'Umieść naklejki',
          goToSection: 'Przejdź do rozdziału',
          sectionHelp: 'Pomoc z rozdziałami',
          addSection: 'Dodaj rozdział',
          sectionDeleteWarning:
            'Gdy usuniesz rozdział, wszystkie powiązane naklejki i strony również zostaną usunięte.',
          stickerDeleteWarning:
            'Gdy usuniesz naklejkę, powiązany obraz naklejki również zostanie trwale usunięty. (Dotyczy to także obrazów zebranych przez link do naklejek.)',
          defaultStickerName: 'Nowa naklejka',
        },
        stickerlink: {
          status: 'Status',
          headline: 'Zebrano %{count} naklejek',
          sublineActive: 'Twój link do naklejek jest aktywny.',
          sublineInactive: 'Twój link do naklejek jest nieaktywny.',
          yourLink: 'Twój link do naklejek',
          description:
            'Z linkiem do naklejek możesz łatwo i szybko zebrać zdjęcia od wszystkich! Po prostu skopiuj i wyślij.',
          copyLink: 'Skopiuj link do schowka',
          isActive: 'Link do naklejek aktywny',
          isActiveHint: 'Włącz/wyłącz wgrywanie zdjęć',
          title: 'Pytaj o tytuł',
          titleHint: 'Pokazuje pole, gdzie można wpisać tytuł naklejki',
          surpriseMode: 'Tryb niespodzianki',
          surpriseModeHint:
            'Usuwa wszystkie odniesienia do Stickerstars i produktu',
          anonymousUrl: 'Anonimowy URL',
          message: 'Wiadomość osobista',
          viewLink: 'Zobacz link',
        },
        images: {
          ownImages: 'Własne obrazy',
          stockImages: 'Biblioteka',
          imagesPlural: 'Obrazy',
          help: 'Pomoc z tłami',
          hint:
            'W tym obszarze możesz wgrywać zdjęcia tła do swojego albumu. Tworzysz i zarządzasz zdjęciami do swoich naklejek',
          stockHint:
            'Tutaj znajdziesz duży wybór darmowych obrazów do swojego albumu. Wystarczy wpisać hasło lub temat w polu wyszukiwania. Przeciągnij obraz na płótno, aby umieścić go w albumie.',
          maxImagesError:
            'Maksymalnie można wgrać %{maxImages} obrazów jednocześnie.',
          deleteWarning:
            'Gdy usuniesz obraz tła, zostanie on również usunięty ze wszystkich stron albumu.',
          stockSearchPlaceholder: 'Przeszukaj bibliotekę...',
          stickerDropWarning:
            'Czy chcesz zrobić naklejkę z tego obrazu tła? Alternatywnie możesz też wgrać obrazy naklejek w sekcji "Naklejki".',
        },
        layouts: {
          hint:
            'Tutaj znajdziesz różne układy stron do projektowania swojego albumu. Po prostu przeciągnij je na (pustą lub istniejącą) stronę na płótnie, aby umieścić je w albumie.',
          placeWarning:
            'Gdy zastosujesz układ do strony z zawartością, jej zawartość zostanie utracona. Czy jesteś pewien?',
        },
        styles: {
          headline: 'Dostosuj styl',
          colors: 'Zmień styl kolorów',
          fonts: 'Zmień styl czcionek',
          stickers: 'Zmień design naklejek',
          tryPresets: 'Wypróbuj szablony',
          custom: 'Wprowadź własne kolory',
          changeColor: 'Wybierz kolor do zmiany',
          primaryColor: 'Kolor podstawowy',
          secondaryColor: 'Kolor drugorzędny',
          tertiaryColor: 'Kolor trzeci',
          yourColors: 'Twój styl kolorów',
        },
        review: {
          noComments:
            'Nie mamy dla Ciebie komentarzy, Twój album jest gotowy do druku!',
          sendFeedback: 'Wyślij opinię',
          printApproval: 'Akceptacja do druku',
          printApprovalTooltip:
            'Czy uwzględniłeś wszystkie komentarze? W takim razie możesz nam tutaj dać swoją zgodę na druk. Zmiany nie będą potem możliwe',
        },
      },

      stockPanel: {
        defaultQuery: 'Tekstura',
      },
      stickerUploadsModal: {
        selectStickerHint: 'Wybierz naklejkę, aby ją edytować.',
        headline: 'Wgrane (%{count})',
        submit: 'Dodaj %{count} naklejek',
      },
      stickerForm: {
        headline: 'Edytuj naklejkę',
        namePlaceholder: 'Wprowadź nazwę',
        positionPlaceholder: 'Wprowadź stanowisko',
        doubleSticker: 'Podwójna naklejka',
        addImage: 'Dodaj zdjęcie',
        changeImage: 'Zmień zdjęcie',
        downloadImage: 'Pobierz zdjęcie',
        fitFace: 'Dopasuj twarz',
        noFace: 'Nie znaleziono twarzy',
        stickerLayout: 'Układ',
        stickerCell: 'Obszar naklejki',
        deleteWarning:
          'Gdy usuniesz naklejkę, powiązany obraz naklejki również zostanie trwale usunięty. (Dotyczy to także obrazów zebranych przez link do naklejek.)',
      },
    },
    stickerUploadForm: {
      nameLabel: 'Twoje imię',
      namePlaceholder: 'Twoje imię',
      dataProtection: 'Dbamy o Twoje dane.',
      dataProtectionLink: 'Tutaj',
      dataProtectionInfo: 'znajdziesz informacje o ochronie danych.',
      lastNameLabel: 'Twoje nazwisko (opcjonalne)',
      lastNamePlaceholder: 'Twoje nazwisko',
      positionLabel: 'Tytuł/Stanowisko (opcjonalne)',
      positionPlaceholder: 'Twój tytuł',
      photoLabel: 'Twoje zdjęcie',
      changePhoto: 'zmień',
      uploadInstructions:
        'Wybierz obraz z dysku lub użyj smartfona, aby zrobić zdjęcie bezpośrednio!',
      successHeading: 'Sukces 👍',
      successMessage:
        'Twój upload się powiódł, dziękujemy! Teraz pozostaje tylko czekać.',
      restartButton: 'zacznij od nowa',
      sectionLabel: 'Twój rozdział',
      dontKnow: 'Nie wiem',
      sectionHelp: 'Jeśli nie wiesz, co tutaj wpisać, zostań przy "Nie wiem".',
      uploadingHeading: 'Wgrywanie...',
      uploadingMessage: 'Dziękujemy za udział — obecnie wgrywamy dane.',
      progressLabel: 'Postęp',
      uploaderHeading: 'Witaj w Photo Uploaderze',
      backButton: 'wstecz',
      nextButton: 'dalej',
      privacyLink: 'Prywatność',
      imprintLink: 'Stopka redakcyjna',
      title: 'Stickerstars | Photo Uploader',
      titleWhiteLabel: 'Photo Uploader',
      loading: 'Ładowanie...',
      albumError:
        'Ten album nie istnieje lub obecnie nie przyjmuje wgrywanych plików.',
    },
    stickerTemplate: {
      name: 'Lisa Lässig',
      position: 'Siostra Panny Młodej',
    },
    profile: {
      headline: 'Mój Profil',
      subline: 'Informacje o Twoim koncie użytkownika',
      ordersHeadline: 'Moje Zamówienia',
      showDetails: 'Pokaż szczegóły',
    },
    showOrder: {
      headline: 'Szczegóły Zamówienia',
      subline: 'Tutaj znajdziesz wszystkie informacje o Twoim zamówieniu.',
      details: 'Szczegóły',
      shipping: 'Wysyłka',
      timeline: 'Oś czasu',
    },

    checkout: {
      backToAlbum: 'powrót do albumu',
      title: 'Twoje Zamówienie',
      errorMessage: 'Ups, coś poszło nie tak. Spróbuj ponownie.',
      personalInfoMessage:
        'Świetnie, teraz potrzebujemy tylko kilku danych osobowych od Ciebie – i gotowe.',
      previewConfirmation: 'Czy wszystko wygląda tak, jak chcesz?',
      firstNameRequired: 'Imię jest wymagane',
      lastNameRequired: 'Nazwisko jest wymagane',
      emailInvalid: 'Email jest nieprawidłowy',
      addressRequired: 'Ulica i numer domu są wymagane',
      zipRequired: 'Kod pocztowy jest wymagany',
      cityRequired: 'Miasto jest wymagane',
      countryRequired: 'Kraj jest wymagany',
      phoneNumberFormat: 'Numer telefonu może zawierać tylko cyfry i spacje',
      phoneNumberLength: 'Numer telefonu może mieć maks. 20 znaków',
      commentLength: 'Komentarz może mieć maks. 500 znaków',
      companyRequired: 'Firma jest wymagana',
      clubRequired: 'Klub jest wymagany',
      printConsent:
        'W celu realizacji procesu produkcji, udzielam Stickerstars dostępu do mojego albumu.',
      orderWithCost: 'Złóż zamówienie z płatnością',
      backToOrderDetails: 'Powrót do szczegółów zamówienia',
      orderConfirmation: 'Dziękujemy, Twoje zamówienie jest przetwarzane.',
      nextStepPackageContents: 'Następny krok: Zawartość Twojej paczki',
      eventDateRequired: 'Data wydarzenia jest wymagana',
      nextStepYourData: 'Następny krok: Twoje dane',
      backToAlbumPreview: 'Powrót do podglądu albumu',
      contactHeading: 'Kontakt',
      email: 'Email',
      deliveryBillingAddress: 'Adres dostawy/faktury',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      streetAndNumber: 'Ulica i numer',
      addressAddition: 'Dodatkowe informacje adresowe',
      optional: 'opcjonalne',
      zipCode: 'Kod pocztowy',
      city: 'Miasto',
      country: 'Kraj',
      phone: 'Telefon',
      quoteNumber: 'Numer oferty',
      remarks: 'Uwagi',
      commentPlaceholder: 'Masz coś jeszcze na myśli? Napisz do nas.',
      albumsLabel: 'Wybierz liczbę albumów',
      albumsNeeded: 'Potrzebuję <b>%{count} albumów</b>',
      chooseAdditionalOptions: 'Wybierz z naszych dodatkowych opcji',
      eventLabel: 'Kiedy odbywa się Twoje wydarzenie?',
      orderSummary: 'Podsumowanie zamówienia',
      priceIncludingShipping: 'Cena z wysyłką',
      vat: 'VAT',
      totalCost: 'Koszt całkowity',
      estimatedDeliveryDate: 'przybliżona data dostawy: <b>%{date}</b>',
      deliveryWarning:
        'To może być napięte. Prosimy o kontakt przed złożeniem zamówienia.',
      voucherInvalid: 'Ten kod jest nieprawidłowy',
      voucherInactive: 'Ten kod jest już nieaktywny',
      voucherTaken: 'Ten kod został już wykorzystany',
      voucherApplied: 'Twój voucher %{code} został zastosowany! 🎉',
      haveVoucher: 'Masz voucher?',
      voucherCodePlaceholder: 'Kod vouchera',
      organizationLabel: 'Organizacja',
      countryLabels: {
        DE: 'Niemcy',
        CH: 'Szwajcaria',
        AT: 'Austria',
        LU: 'Luksemburg',
        FR: 'Francja',
        NL: 'Holandia',
        BE: 'Belgia',
        IE: 'Irlandia',
        FI: 'Finlandia',
        IT: 'Włochy',
        PL: 'Polska',
      },
      stickerPackagingOptionsLabel: 'Jak mamy zapakować Twoje naklejki?',
      stickerPackagingOptions: {
        stickerpack: {
          title: 'Paczki naklejek',
          text:
            'Nasz klasyk: Każda paczka zawiera 4 losowo wymieszane naklejki, zapewniając element niespodzianki przy otwieraniu – idealne rozwiązanie dla żywej i interaktywnej akcji kolekcjonerskiej, podczas której goście mogą wymieniać się duplikatami i wspólnie uzupełniać swoje albumy.',
        },
        stickerboxSingleVariety: {
          title: 'Posegregowane pudełka naklejek',
          text:
            'Nasza "opcja wizytówkowa": Każde pudełko zawiera wyłącznie identyczne naklejki jednego wzoru. Ta opcja jest idealna dla organizatorów, którzy chcą zapewnić gościom własne naklejki w wielu kopiach.',
        },
      },
    },
    settingsModal: {
      title: 'Ustawienia',
      generalTab: 'Ogólne',
      usersTab: 'Zarządzaj użytkownikami',
      emailNotifications: 'Powiadomienia email',
      emailNotificationsHelp:
        'Wyślemy Ci powiadomienie na przykład, gdy nowe naklejki zostaną wgrane do Twojego albumu przez link do naklejek.',
      albumPreview: 'Podgląd albumu',
      albumPreviewHelp:
        'Włącz podgląd albumu, aby wygenerować link do udostępnienia. Link pozwala tylko na przeglądanie, nie na edycję. Uwaga: Każdy może zobaczyć Twój album z tym linkiem. Udostępniaj go tylko osobom, którym ufasz.',
      language: 'Język albumu',
      languageHelp: 'Język albumu kontroluje sposób wyświetlania dat i liczb.',
      saveError: 'Błąd podczas zapisywania',
      users: {
        error: 'To nie zadziałało. Spróbuj ponownie później.',
        inviteUser: 'Zaproś użytkownika',
        emailPlaceholder: 'Adres email',
        sendInvitation: 'Wyślij zaproszenie',
        cancel: 'Anuluj',
        usersHeader: 'Użytkownicy',
        ownerBadge: 'Właściciel',
        removeUser: 'Usuń',
        invitationsHeader: 'Otwarte zaproszenia',
        cancelInvitation: 'Anuluj',
        noInvitations: 'Brak otwartych zaproszeń.',
        invitationsError: 'Wystąpił błąd.',
      },
    },
    notifications: {
      previewMode:
        'Twój album jest w trybie podglądu, zmiany nie są obecnie możliwe.',
      autoSave: 'Twoje zmiany są automatycznie zapisywane.',
    },
    invitations: {
      error: 'To nie zadziałało. Spróbuj ponownie później.',
      joinMessage:
        '<b>%{inviterEmail}</b> zaprosił Cię do dołączenia do projektu naklejek <b>%{albumTitle}</b>. Aby kontynuować, kliknij przycisk i zaloguj się lub utwórz konto:',
      acceptInvitation: 'Przyjmij zaproszenie',
      accessInfo:
        'Po zalogowaniu będziesz mieć dostęp do albumu i naklejek i możesz zacząć edycję.',
      moreInfo:
        'Tutaj znajdziesz więcej informacji o STICKERSTARS i Twoim projekcie.',
      companyInfo:
        'STICKERSTARS Technologies GmbH, Wilhelm-Kabus-Str. 42/44, 10829 Berlin, Niemcy',
    },
    models: {
      title: 'Tytuł',
      createdAt: 'Utworzono',
      album: {
        one: 'Album',
        plural: 'Albumy',
        releaseDate: 'Data wydarzenia',
      },
      order: {
        id: 'Numer zamówienia',
        lineItems: 'Pozycje',
        net: 'Netto',
        vat: 'VAT',
        deliveryNote: 'List przewozowy',
        invoice: 'Faktura',
        comment: 'Komentarz',
      },
      lineItem: {
        quantity: 'Ilość',
      },
    },
    cookies: {
      title: '🍪 Pliki cookie i Prywatność',
      description:
        'Aby zapewnić Ci najlepsze możliwe doświadczenie przeglądania, chcielibyśmy zapisać kilka plików cookie! Więcej informacji na ten temat znajdziesz w naszej <a target="__blank" aria-label="Link do strony o prywatności" class="cc-link" href="https://stickerstars.de/datenschutz">Polityce Prywatności</a>. Kliknij "Zgadzam się", aby zaakceptować pliki cookie i móc odwiedzić naszą stronę!',
      acceptAll: 'Zgadzam się',
      settings: 'Ustawienia',

      settingsModal: {
        title: 'Ustawienia plików cookie',
        saveSettings: 'Zapisz ustawienia',
        acceptAll: 'Zaakceptuj wszystkie',
        rejectAll: 'Odrzuć wszystkie',
        close: 'zamknij',
        intro:
          'Używamy plików cookie, aby zapewnić podstawowe funkcje strony i poprawić Twoje doświadczenie online. Możesz wybrać dla każdej kategorii, czy akceptujesz te pliki cookie — czy nie.',
        necessary: {
          title: 'Niezbędne pliki cookie',
          description:
            'Niezbędne pliki cookie (i podobne technologie) są wymagane do sprawnego działania strony i nie mogą być wyłączone.',
        },
        analytics: {
          title: 'Pliki cookie do analityki i marketingu',
          description:
            'Te pliki cookie zbierają informacje o tym, jak korzystasz ze strony, które strony odwiedzasz i które linki kliknąłeś. Wszystkie dane są anonimowe i nie mogą być użyte do Twojej identyfikacji',
        },
        moreInfo: {
          title: 'Więcej informacji',
          description:
            'Więcej informacji o naszej polityce dotyczącej plików cookie i polityce prywatności znajdziesz na naszej stronie <a class="cc-link" target="_BLANK" href="https://www.stickerstars.de/datenschutz">Prywatność</a>.',
        },
      },
    },
    footer: {
      imprint: 'Nota prawna',
      privacy: 'Polityka Prywatności',
    },
  },
  business: {
    editor: {
      stockPanel: {
        defaultQuery: 'Praca zespołowa',
      },
    },
    stickerTemplate: {
      name: 'Agathe Anklam',
      position: 'Dyrektor Finansowy',
    },
    checkout: {
      organizationLabel: 'Firma',
    },
  },
  sports: {
    editor: {
      stockPanel: {
        defaultQuery: 'Sport',
      },
      stickerTemplate: {
        name: 'Jutta Juist',
        position: 'Pomocnik Prawy',
      },
    },
    checkout: {
      organizationLabel: 'Klub / Organizacja',
    },
  },
};
