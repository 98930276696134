import { symbols } from '../useAppEnvironment';

export default {
  default: {
    languages: {
      de: 'German',
      en: 'English',
      fr: 'French',
      it: 'Italian',
      pl: 'Polish',
    },
    rename: 'Rename',
    upload: 'Upload',
    delete: 'Delete',
    duplicate: 'Duplicate',
    moveUp: 'Move up',
    moveDown: 'Move down',
    stickersPlural: 'Stickers',
    save: 'Save',
    cancel: 'Cancel',
    learnMore: 'Learn more',
    settings: 'Settings',
    search: 'Search',
    here: 'here',
    undo: 'Undo',
    redo: 'Redo',
    on: 'on',
    off: 'off',
    help: 'Help',
    pagination: 'Page %{page} of %{total}',
    genericSuccess: 'Successfully saved.',
    genericError: 'An error occurred. Please try again.',
    [symbols.business]: 'Business',
    [symbols.wedding]: 'Wedding',
    [symbols.sports]: 'Sports',
    [symbols.birthday]: 'Birthday',
    [symbols.school]: 'School',
    [symbols.community]: 'Community',
    selectOption: 'Please select',
    back: 'Back',
    next: 'Next',
    createAlbum: 'Create album',
    errorBoundary:
      'Something went wrong with the last action. Our team has been notified. Your last state was saved.',
    render: {
      stickerpackText: 'Open here',
    },
    start: {
      headline: 'My Albums',
      subline: 'Here you can find the albums you created.',
      accountActivatedNotification:
        'Your account has been successfully activated.',
    },
    wizard: {
      autosaveModal: {
        headline: 'Nice to have you back!',
        text:
          'We found an old configuration state. Would you like to load it or start fresh?',
        confirm: 'Yes, please load',
        decline: 'Start fresh',
      },
      pageHint: 'Use the buttons to navigate through the preview!',
      tabs: {
        blueprints: {
          headline: 'Choose a template',
          content:
            'Our designers have created great templates for you. Choose your desired template and customize it to your personal taste in the next step.',
        },
        data: {
          headline: 'Almost there!',
          content: "Just a few last details, then we're ready to go!",
          organizationName: 'Company name',
          numEmployees: 'Number of employees',
          contactName: 'Your Name',
          phone: 'Phone number',
          phoneDescription: 'We can offer you the best service over the phone.',
          contactRole: 'Role',
          contactRoleDescription:
            'What describes your role within the company best?',
          contactRoles: {
            member: 'Team Member',
            lead: 'Team Lead / Project Management',
            senior: 'Senior Management',
            executive: 'Executive',
          },
        },
      },
    },
    confirmModal: {
      defaultHeadline: 'Are you sure?',
      defaultConfirm: 'I am sure',
    },
    editor: {
      autosave: {
        connectionError:
          'Server connection error, your changes may not be saved.',
        mergeError: 'Your album could not be saved, we will keep trying.',
      },
      meta: {
        orderNow: 'Order now',
        priceLabel: 'from 285 €',
        currentSpread: 'Current page',
        allSpreads: 'All pages',
        grid: 'Grid',
        elements: 'Page elements',
        showBleed: 'Show final format',
        terms: 'Terms and conditions',
        customerInformation: 'Customer information',
        privacy: 'Privacy',
        contact: 'Contact us',
        mobileAlert:
          "It's easier on a laptop! Switch to a device with a larger screen to design your album.",
        mobileCta: 'Switch device',
        mobileModalHeadline: 'Continue here',
        mobileModalContent:
          "Click the button – we'll send you a link via email so you can continue editing your album on your laptop.",
        mobileModalCta: 'Send link',
        mobileModalConfirmation: 'All set, check your inbox!',
        profile: 'Profile',
        logout: 'Log out',
        saveError: 'There was a problem saving your album',
        stickerCellDropError:
          'Only one image can be added to each sticker field.',
        printPreview: {
          preview: 'Print preview',
          turnOn: 'Turn on print preview',
          turnOff: 'Turn off print preview',
        },
      },
      imageUpload: {
        maxImagesError: 'Up to %{max} images can be uploaded simultaneously.',
        uploadError: 'There was a problem uploading %{fileName}.',
        sizeError: 'Image must be between %{min} and %{max} mb in size.',
        typeError: 'Image must be of type %{types}.',
        dimensionsError: 'Image can be max. %{maxPixels} pixels.',
      },
      spreadMeta: {
        moveUp: 'Move page up',
        moveDown: 'Move page down',
        duplicate: 'Duplicate page',
        delete: 'Delete page',
        deleteConfirmation:
          'When you delete the spread, all elements placed on it will also be deleted (text, sticker fields, shapes etc.)',
        pageRange: 'Pages %{start} - %{end}',
        addSpread: 'Insert empty page',
      },
      toolbar: {
        fill: 'Fill',
        strokeColor: 'Border color',
        strokeWidth: 'Border width',
        opacity: 'Transparency',
        fontFamily: 'Font',
        fontSize: 'Font size',
        textColor: 'Text color',
        lineHeightAndAlign: 'Line height & alignment',
        alignLeft: 'Align left',
        alignCenter: 'Center',
        alignRight: 'Align right',
        alignJustify: 'Justify',
        lineHeight: 'Line height',
        symbolText: 'Automatic text',
        group: 'Group',
        ungroup: 'Ungroup',
        finishEditing: 'Finish editing',
        editImage: 'Edit image',
        uploadImage: 'Upload image',
        flip: 'Flip',
        toFront: 'Bring to front',
        toBack: 'Send to back',
        editSticker: 'Edit sticker',
        uploadStickerImage: 'Upload sticker image',
        editText: 'Edit text',
        showLess: 'less...',
        showMore: 'more...',
        title: 'Heading',
        subline: 'Subtitle',
        text: 'Text',
        bold: 'Bold',
        italic: 'Italic',
        uppercase: 'Uppercase',
        fontSizeAutoFitHint: 'Font size may be reduced to fit text in one line',
        autoFit: 'Auto-fit',
        automaticText: 'Automatic text',
        freeText: '(Free text)',
        section: 'Chapter name',
        album: 'Album title',
        release_date: 'Event date',
        pagenum: 'Page number',
        totalpages: 'Total pages',
        totalstickers: 'Total stickers',
        toc_sections: 'Table of contents / Chapters',
        toc_pages: 'Table of contents / Pages',
        unlinkSticker: 'Unlink sticker',
        newText: 'New text...',
        symbolNote: 'This is an automatic text that cannot be edited.',
      },
      elements: {
        text: 'Text field',
        circle: 'Circle',
        rectangle: 'Rectangle',
        line: 'Line',
        stickerCell: 'Sticker area',
        comment: 'Comment',
      },
      albumTitle: {
        savedMessage: 'Last saved on %{date}',
        titlePlaceholder: 'Album title',
        releaseDate: 'Event date',
        releaseDateTooltip: 'Event date – when should your album be ready?',
      },
      onboarding: {
        headline: 'Welcome',
        subline:
          'to the Designer! On this page you will find everything you need to get started. If you have any questions, feel free to contact us anytime.',
        toAlbum: 'To album',
        salesContactAlert:
          "With an organization of this size, you have a dedicated contact person; we're happy to handle concept, design and project management for you. Feel free to schedule a no-obligation discussion in my calendar:",
        schedulerLink: 'Schedule appointment',
        usefulLinks: 'Useful links',
        tutorial: {
          headline: 'Read tutorial',
          subline: "It's all simple: Our tutorial helps you create your album.",
        },
        faq: {
          headline: 'Frequently asked questions',
          subline:
            'Here you will find our answers to the most common questions about the collecting experience.',
        },
        budget: {
          headline: 'Budget',
          subline:
            'Click here to access the price calculator and plan your budget.',
        },
        firstSteps: 'First steps',
        getToKnowDesigner: 'Get to know the Designer',
        steps: {
          team: {
            headline: 'Invite your team',
            text:
              "It's faster this way! Invite the people who will help you create your album.",
          },
          albumTitle: {
            headline: 'Adjust the title and event date',
            text: 'Give your album a name and set the event date.',
          },
          sticker: {
            headline: 'Create your first sticker',
            text:
              'Click here to access sticker management – there you can create your first stickers.',
          },
          section: {
            headline: 'Adjust the chapters',
            text:
              'The chapters structure your album. Click here to access chapter management.',
          },
          stickerlink: {
            headline: 'Prepare your sticker link',
            text:
              'Through the sticker link, your sticker stars can upload their own photos for stickers.',
          },
          image: {
            headline: 'Place a background image',
            text:
              'Drag a photo onto one of the pages to place a background photo.',
          },
        },
      },
      sidebar: {
        tabs: {
          onboarding: 'Start',
          stickers: 'Stickers',
          stickerlink: 'Sticker link',
          background: 'Background',
          layouts: 'Layouts',
          styles: 'Design',
          jobs: 'Export',
          review: 'Feedback',
        },
        stickers: {
          expand: 'Expand chapter',
          collapse: 'Collapse chapter',
          search: 'Search stickers',
          newStickers: 'New stickers',
          newStickersNotification:
            '<b>%{count} new stickers</b> have been uploaded through your sticker link.',
          collectStickers: 'Collect stickers',
          unplacedStickers: 'Unplaced stickers',
          stickerPlacementHelp: 'Place stickers',
          goToSection: 'Go to chapter',
          sectionHelp: 'Help with chapters',
          addSection: 'Add chapter',
          sectionDeleteWarning:
            'When you delete the chapter, all associated stickers and pages will also be deleted.',
          stickerDeleteWarning:
            'When you delete the sticker, the associated sticker image will also be permanently deleted. (This also applies to images you collected via the sticker link.)',
          defaultStickerName: 'New sticker',
        },
        stickerlink: {
          status: 'Status',
          headline: '%{count} stickers collected',
          sublineActive: 'Your sticker link is active.',
          sublineInactive: 'Your sticker link is inactive.',
          yourLink: 'Your sticker link',
          description:
            'With the sticker link you can easily and quickly collect photos from all people! Simply copy and send.',
          copyLink: 'Copy link to clipboard',
          isActive: 'Sticker link active',
          isActiveHint: 'Enable/disable photo upload',
          title: 'Ask for title',
          titleHint: 'Shows a field where the sticker title can be entered',
          surpriseMode: 'Surprise mode',
          surpriseModeHint:
            'Removes all references to Stickerstars and the product',
          anonymousUrl: 'Anonymous URL',
          message: 'Personal message',
          viewLink: 'View link',
        },
        images: {
          ownImages: 'Own images',
          stockImages: 'Library',
          imagesPlural: 'Images',
          help: 'Help with backgrounds',
          hint:
            'In this area you can upload background photos for your album. You create and manage photos for your stickers',
          stockHint:
            'Here you will find a large selection of free images for your album. Simply enter a term or topic in the search field. Drag an image onto the canvas to place it in the album.',
          maxImagesError:
            'A maximum of %{maxImages} images can be uploaded simultaneously.',
          deleteWarning:
            'When you delete the background image, it will also be removed from all album pages.',
          stockSearchPlaceholder: 'Search library...',
          stickerDropWarning:
            'Would you like to make a sticker from this background image? Alternatively, you can also upload sticker images under "Stickers".',
        },
        layouts: {
          hint:
            'Here you will find a variety of page layouts for designing your album. Simply drag them onto an (empty or existing) page on the canvas to place them in the album.',
          placeWarning:
            'When you apply a layout to a page with content, its content will be lost. Are you sure?',
        },
        styles: {
          headline: 'Customize style',
          colors: 'Change color style',
          fonts: 'Change font style',
          stickers: 'Change sticker design',
          tryPresets: 'Try templates',
          custom: 'Enter custom colors',
          changeColor: 'Choose a color to change',
          primaryColor: 'Primary color',
          secondaryColor: 'Secondary color',
          tertiaryColor: 'Tertiary color',
          yourColors: 'Your color style',
        },
        review: {
          noComments:
            'We have no comments for you, your album is ready to print!',
          sendFeedback: 'Send feedback',
          printApproval: 'Print approval',
          printApprovalTooltip:
            'Have you considered all comments? Then you can give us your OK for printing here. Changes will not be possible afterwards',
        },
      },
      stockPanel: {
        defaultQuery: 'Texture',
      },
      stickerUploadsModal: {
        selectStickerHint: 'Select a sticker to edit it.',
        headline: 'Uploads (%{count})',
        submit: 'Add %{count} stickers',
      },
      stickerForm: {
        headline: 'Edit sticker',
        namePlaceholder: 'Enter name',
        positionPlaceholder: 'Enter position',
        doubleSticker: 'Double sticker',
        addImage: 'Add photo',
        changeImage: 'Change photo',
        downloadImage: 'Download photo',
        fitFace: 'Fit face',
        noFace: 'No face found',
        stickerLayout: 'Layout',
        stickerCell: 'Sticker area',
        deleteWarning:
          'When you delete the sticker, the associated sticker image will also be permanently deleted. (This also applies to images you collected via the sticker link.)',
      },
    },
    stickerUploadForm: {
      nameLabel: 'Your name',
      namePlaceholder: 'Your name',
      dataProtection: 'We take good care of your data.',
      dataProtectionLink: 'Here',
      dataProtectionInfo: 'you can find information about data protection.',
      lastNameLabel: 'Your last name (optional)',
      lastNamePlaceholder: 'Your last name',
      positionLabel: 'Title/Position (optional)',
      positionPlaceholder: 'Your title',
      photoLabel: 'Your photo',
      changePhoto: 'change',
      uploadInstructions:
        'Choose an image from your drive or use your smartphone to take one directly!',
      successHeading: 'Success 👍',
      successMessage:
        "Your upload was successful, thank you! Now it's time to look forward to it.",
      restartButton: 'start over',
      sectionLabel: 'Your chapter',
      dontKnow: "I don't know",
      sectionHelp:
        'If you don\'t know what to enter here, just stay with "I don\'t know".',
      uploadingHeading: 'Uploading...',
      uploadingMessage:
        'Thank you for participating — we are currently uploading the data.',
      progressLabel: 'Progress',
      uploaderHeading: 'Welcome to the Photo Uploader',
      backButton: 'back',
      nextButton: 'next',
      privacyLink: 'Privacy',
      imprintLink: 'Imprint',
      title: 'Stickerstars | Photo Uploader',
      titleWhiteLabel: 'Photo Uploader',
      loading: 'Loading...',
      albumError:
        'This album does not exist or is not currently accepting uploads.',
    },
    stickerTemplate: {
      name: 'Lisa Lässig',
      position: "Bride's Sister",
    },
    profile: {
      headline: 'My Profile',
      subline: 'Information about your user account',
      ordersHeadline: 'My Orders',
      showDetails: 'Show details',
    },
    showOrder: {
      headline: 'Order Details',
      subline: 'Here you can find all information about your order.',
      details: 'Details',
      shipping: 'Shipping',
      timeline: 'Timeline',
    },
    checkout: {
      backToAlbum: 'back to album',
      title: 'Your Order',
      errorMessage: 'Oops, something went wrong. Please try again.',
      personalInfoMessage:
        "Great, now we just need a few personal details from you – then you're done.",
      previewConfirmation: 'Does everything look the way you want it?',
      firstNameRequired: 'First name is required',
      lastNameRequired: 'Last name is required',
      emailInvalid: 'Email is invalid',
      addressRequired: 'Street and house number are required',
      zipRequired: 'ZIP code is required',
      cityRequired: 'City is required',
      countryRequired: 'Country is required',
      phoneNumberFormat: 'Phone number can only contain numbers and spaces',
      phoneNumberLength: 'Phone number can be max. 20 characters long',
      commentLength: 'Comment can be max. 500 characters long',
      companyRequired: 'Company is required',
      clubRequired: 'Club is required',
      printConsent:
        'For the production process, I grant Stickerstars access to my album.',
      orderWithCost: 'Place order with payment',
      backToOrderDetails: 'Back to order details',
      orderConfirmation: 'Thank you, your order is being processed.',
      nextStepPackageContents: 'Next step: Your package contents',
      eventDateRequired: 'Event date is required',
      nextStepYourData: 'Next step: Your data',
      backToAlbumPreview: 'Back to album preview',
      contactHeading: 'Contact',
      email: 'Email',
      deliveryBillingAddress: 'Delivery/Billing address',
      firstName: 'First name',
      lastName: 'Last name',
      streetAndNumber: 'Street and number',
      addressAddition: 'Address addition',
      optional: 'optional',
      zipCode: 'ZIP code',
      city: 'City',
      country: 'Country',
      phone: 'Phone',
      quoteNumber: 'Quote number',
      remarks: 'Remarks',
      commentPlaceholder:
        'Do you have anything else on your mind? Write to us.',
      albumsLabel: 'Choose number of albums',
      albumsNeeded: 'I need <b>%{count} albums</b>',
      chooseAdditionalOptions: 'Choose from our additional options',
      eventLabel: 'When is your event taking place?',
      orderSummary: 'Order summary',
      priceIncludingShipping: 'Price including shipping',
      vat: 'VAT',
      totalCost: 'Total cost',
      estimatedDeliveryDate: 'est. delivery date: <b>%{date}</b>',
      deliveryWarning:
        'This could be tight. Please contact us before placing the order.',
      voucherInvalid: 'This code is not valid',
      voucherInactive: 'This code is no longer active',
      voucherTaken: 'This code has already been used',
      voucherApplied: 'Your voucher %{code} has been applied! 🎉',
      haveVoucher: 'Do you have a voucher?',
      voucherCodePlaceholder: 'Voucher code',
      organizationLabel: 'Organization',
      countryLabels: {
        DE: 'Germany',
        CH: 'Switzerland',
        AT: 'Austria',
        LU: 'Luxembourg',
        FR: 'France',
        NL: 'Netherlands',
        BE: 'Belgium',
        IE: 'Ireland',
        FI: 'Finland',
        IT: 'Italy',
        PL: 'Poland',
      },
      stickerPackagingOptionsLabel: 'How should we package your stickers?',
      stickerPackagingOptions: {
        stickerpack: {
          title: 'Stickerpacks',
          text:
            'Our classic: Each pack contains 4 randomly mixed stickers for a surprise moment when opening - perfect for a lively and interactive collection activity where guests can swap duplicate stickers and complete their albums together.',
        },
        stickerboxSingleVariety: {
          title: 'Single-variety stickerboxes',
          text:
            'Our "business card option": Each box contains only identical stickers of one motif. This option is ideal for event organizers who want to provide guests with multiple copies of their own stickers.',
        },
      },
    },
    settingsModal: {
      title: 'Settings',
      generalTab: 'General',
      usersTab: 'Manage users',
      emailNotifications: 'Email notifications',
      emailNotificationsHelp:
        'We will send you a notification, for example, when new stickers are loaded into your album via the sticker link.',
      albumPreview: 'Album preview',
      albumPreviewHelp:
        'Enable album preview to generate a shareable link. The link only allows viewing, not editing. Note: Anyone can view your album with this link. Only share it with people you trust.',
      language: 'Album Language',
      languageHelp:
        'The album language controls how dates and numbers are displayed.',
      saveError: 'Error while saving',
      users: {
        error: "That didn't work. Please try again later.",
        inviteUser: 'Invite user',
        emailPlaceholder: 'Email address',
        sendInvitation: 'Send invitation',
        cancel: 'Cancel',
        usersHeader: 'Users',
        ownerBadge: 'Owner',
        removeUser: 'Remove',
        invitationsHeader: 'Open invitations',
        cancelInvitation: 'Cancel',
        noInvitations: 'No open invitations.',
        invitationsError: 'An error occurred.',
      },
    },
    notifications: {
      previewMode:
        'Your album is in preview mode, changes are currently not possible.',
      autoSave: 'Your changes are automatically saved.',
    },
    invitations: {
      error: "That didn't work. Please try again later.",
      joinMessage:
        '<b>%{inviterEmail}</b> has invited you to join the sticker project <b>%{albumTitle}</b>. To continue, please click the button and sign in or create an account:',
      acceptInvitation: 'Accept invitation',
      accessInfo:
        'After signing in, you will have access to the album and stickers and can start editing.',
      moreInfo:
        'Here you can find more information about STICKERSTARS and your project.',
      companyInfo:
        'STICKERSTARS Technologies GmbH, Wilhelm-Kabus-Str. 42/44, 10829 Berlin, Germany',
    },
    models: {
      title: 'Title',
      createdAt: 'Created on',
      album: {
        one: 'Album',
        plural: 'Albums',
        releaseDate: 'Event date',
      },
      order: {
        id: 'Order number',
        lineItems: 'Items',
        net: 'Net',
        vat: 'VAT',
        deliveryNote: 'Delivery note',
        invoice: 'Invoice',
        comment: 'Comment',
      },
      lineItem: {
        quantity: 'Quantity',
      },
    },
    cookies: {
      title: '🍪 Cookies and Privacy',
      description:
        'To provide you with the best possible browsing experience, we would like to store a few cookies! You can find more information about this in our <a target="__blank" aria-label="Link to privacy page" class="cc-link" href="https://stickerstars.de/datenschutz">Privacy Policy</a>. Please click "I agree" to accept the cookies and be able to visit our website!',
      acceptAll: 'I agree',
      settings: 'Settings',
      settingsModal: {
        title: 'Cookie settings',
        saveSettings: 'Save settings',
        acceptAll: 'Accept all',
        rejectAll: 'Reject all',
        close: 'close',
        intro:
          'We use cookies to ensure the basic functions of the website and to improve your online experience. You can choose for each category whether you accept these cookies -- or not.',
        necessary: {
          title: 'Necessary cookies',
          description:
            'Necessary cookies (and similar technologies) are required for smooth operation of the website and cannot be disabled.',
        },
        analytics: {
          title: 'Cookies for analytics and marketing',
          description:
            'These cookies collect information about how you use the website, which pages you visit and which links you have clicked. All data is anonymized and cannot be used to identify you',
        },
        moreInfo: {
          title: 'More information',
          description:
            'For more information about our cookie policy and privacy policies, please visit our <a class="cc-link" target="_BLANK" href="https://www.stickerstars.de/datenschutz">Privacy</a> page.',
        },
      },
    },
    footer: {
      imprint: 'Legal Notice',
      privacy: 'Privacy Policy',
    },
  },
  business: {
    editor: {
      stockPanel: {
        defaultQuery: 'Teamwork',
      },
    },
    stickerTemplate: {
      name: 'Agathe Anklam',
      position: 'CFO',
    },
    checkout: {
      organizationLabel: 'Company',
    },
  },
  sports: {
    editor: {
      stockPanel: {
        defaultQuery: 'Sport',
      },
      stickerTemplate: {
        name: 'Jutta Juist',
        position: 'Right Midfielder',
      },
    },
    checkout: {
      organizationLabel: 'Club / Organization',
    },
  },
};
