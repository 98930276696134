import { symbols } from '../useAppEnvironment';

export default {
  default: {
    languages: {
      de: 'Allemand',
      en: 'Anglais',
      fr: 'Français',
      it: 'Italien',
      pl: 'Polonais',
    },
    rename: 'Renommer',
    upload: 'Télécharger',
    delete: 'Supprimer',
    duplicate: 'Dupliquer',
    moveUp: 'Déplacer vers le haut',
    moveDown: 'Déplacer vers le bas',
    stickersPlural: 'Stickers',
    save: 'Enregistrer',
    cancel: 'Annuler',
    learnMore: 'En savoir plus',
    settings: 'Paramètres',
    search: 'Rechercher',
    here: 'ici',
    undo: 'Annuler',
    redo: 'Rétablir',
    on: 'activé',
    off: 'désactivé',
    help: 'Aide',
    pagination: 'Page %{page} sur %{total}',
    genericSuccess: 'Enregistré avec succès.',
    genericError: "Une erreur s'est produite. Veuillez réessayer.",
    [symbols.business]: 'Entreprise',
    [symbols.wedding]: 'Mariage',
    [symbols.sports]: 'Sport',
    [symbols.birthday]: 'Anniversaire',
    [symbols.school]: 'École',
    [symbols.community]: 'Communauté',
    selectOption: 'Veuillez sélectionner',
    back: 'Retour',
    next: 'Suivant',
    createAlbum: 'Créer un album',
    errorBoundary:
      "Une erreur s'est produite lors de la dernière action. Notre équipe a été informée. Votre dernier état a été sauvegardé.",
    render: {
      stickerpackText: 'Ouvrir ici',
    },
    start: {
      headline: 'Mes Albums',
      subline: 'Ici, vous trouverez les albums que vous avez créés.',
      accountActivatedNotification: 'Votre compte a été activé avec succès.',
    },
    wizard: {
      autosaveModal: {
        headline: 'Content de vous revoir !',
        text:
          'Nous avons trouvé un ancien état de configuration. Voulez-vous le charger ou recommencer à zéro ?',
        confirm: 'Oui, charger',
        decline: 'Recommencer',
      },
      pageHint: "Utilisez les boutons pour naviguer dans l'aperçu !",
      tabs: {
        blueprints: {
          headline: 'Choisissez un modèle',
          content:
            "Nos designers ont créé d'excellents modèles pour vous. Choisissez votre modèle préféré et personnalisez-le selon vos goûts à l'étape suivante.",
        },
        data: {
          headline: 'Presque terminé !',
          content: 'Plus que quelques détails, et nous pourrons commencer !',
          organizationName: "Nom de l'entreprise",
          numEmployees: "Nombre d'employés",
          contactName: 'Ton nom',
          phone: 'Téléphone',
          phoneDescription:
            "Nous pouvons t'offrir le meilleur service par téléphone (en anglais uniquement).",
          contactRole: 'Position',
          contactRoleDescription:
            "Qu'est-ce qui décrit le mieux ta position dans l'entreprise ?",
          contactRoles: {
            member: "Membre de l'équipe",
            lead: "Chef d'équipe / chef de projet",
            senior: 'Senior Management',
            executive: 'Direction',
          },
        },
      },
    },
    confirmModal: {
      defaultHeadline: 'Êtes-vous sûr ?',
      defaultConfirm: 'Je suis sûr',
    },
    editor: {
      autosave: {
        connectionError:
          'Erreur de connexion au serveur, vos modifications peuvent ne pas être sauvegardées.',
        mergeError:
          "Votre album n'a pas pu être sauvegardé, nous continuons d'essayer.",
      },
      meta: {
        orderNow: 'Commander maintenant',
        priceLabel: 'à partir de 285 €',
        currentSpread: 'Page actuelle',
        allSpreads: 'Toutes les pages',
        grid: 'Grille',
        elements: 'Éléments de page',
        showBleed: 'Afficher le format final',
        terms: 'Conditions générales',
        customerInformation: 'Informations client',
        privacy: 'Confidentialité',
        contact: 'Nous contacter',
        mobileAlert:
          "C'est plus facile sur un ordinateur portable ! Passez à un appareil avec un plus grand écran pour concevoir votre album.",
        mobileCta: "Changer d'appareil",
        mobileModalHeadline: 'Continuez ici',
        mobileModalContent:
          "Cliquez sur le bouton – nous vous enverrons un lien par e-mail pour continuer l'édition de votre album sur votre ordinateur portable.",
        mobileModalCta: 'Envoyer le lien',
        mobileModalConfirmation:
          'Tout est prêt, vérifiez votre boîte de réception !',
        profile: 'Profil',
        logout: 'Déconnexion',
        saveError:
          'Un problème est survenu lors de la sauvegarde de votre album',
        stickerCellDropError:
          "Une seule image peut être ajoutée à chaque zone d'sticker.",
        printPreview: {
          preview: "Aperçu d'impression",
          turnOn: "Activer l'aperçu avant impression",
          turnOff: "Désactiver l'aperçu avant impression",
        },
      },
      imageUpload: {
        maxImagesError:
          "Jusqu'à %{max} images peuvent être téléchargées simultanément.",
        uploadError:
          'Un problème est survenu lors du téléchargement de %{fileName}.',
        sizeError: "L'image doit faire entre %{min} et %{max} mb.",
        typeError: "L'image doit être de type %{types}.",
        dimensionsError: "L'image peut faire max. %{maxPixels} pixels.",
      },
      spreadMeta: {
        moveUp: 'Déplacer la page vers le haut',
        moveDown: 'Déplacer la page vers le bas',
        duplicate: 'Dupliquer la page',
        delete: 'Supprimer la page',
        deleteConfirmation:
          "Lorsque vous supprimez la double page, tous les éléments placés dessus seront également supprimés (texte, zones d'stickers, formes, etc.)",
        pageRange: 'Pages %{start} - %{end}',
        addSpread: 'Insérer une page vide',
      },
      toolbar: {
        fill: 'Remplir',
        strokeColor: 'Couleur de bordure',
        strokeWidth: 'Épaisseur de bordure',
        opacity: 'Transparence',
        fontFamily: 'Police',
        fontSize: 'Taille de police',
        textColor: 'Couleur du texte',
        lineHeightAndAlign: 'Hauteur de ligne & alignement',
        alignLeft: 'Aligner à gauche',
        alignCenter: 'Centrer',
        alignRight: 'Aligner à droite',
        alignJustify: 'Justifier',
        lineHeight: 'Hauteur de ligne',
        symbolText: 'Texte automatique',
        group: 'Grouper',
        ungroup: 'Dégrouper',
        finishEditing: "Terminer l'édition",
        editImage: "Modifier l'image",
        uploadImage: 'Télécharger une image',
        flip: 'Retourner',
        toFront: 'Mettre au premier plan',
        toBack: 'Mettre en arrière-plan',
        editSticker: "Modifier l'sticker",
        uploadStickerImage: "Télécharger une image d'sticker",
        editText: 'Modifier le texte',
        showLess: 'moins...',
        showMore: 'plus...',
        title: 'Titre',
        subline: 'Sous-titre',
        text: 'Texte',
        bold: 'Gras',
        italic: 'Italique',
        uppercase: 'Majuscules',
        fontSizeAutoFitHint:
          'La taille de police peut être réduite pour que le texte tienne sur une ligne',
        autoFit: 'Ajustement automatique',
        automaticText: 'Texte automatique',
        freeText: '(Texte libre)',
        section: 'Nom du chapitre',
        album: "Titre de l'album",
        release_date: "Date de l'événement",
        pagenum: 'Numéro de page',
        totalpages: 'Nombre total de pages',
        totalstickers: "Nombre total d'stickers",
        toc_sections: 'Table des matières / Chapitres',
        toc_pages: 'Table des matières / Pages',
        unlinkSticker: "Détacher l'sticker",
        newText: 'Nouveau texte...',
        symbolNote:
          'Ceci est un texte automatique qui ne peut pas être modifié.',
      },
      elements: {
        text: 'Zone de texte',
        circle: 'Cercle',
        rectangle: 'Rectangle',
        line: 'Ligne',
        stickerCell: "Zone d'sticker",
        comment: 'Commentaire',
      },
      albumTitle: {
        savedMessage: 'Dernière sauvegarde le %{date}',
        titlePlaceholder: "Titre de l'album",
        releaseDate: "Date de l'événement",
        releaseDateTooltip:
          "Date de l'événement – quand votre album doit-il être prêt ?",
      },
      onboarding: {
        headline: 'Bienvenue',
        subline:
          "dans le Designer ! Sur cette page, vous trouverez tout ce dont vous avez besoin pour commencer. Si vous avez des questions, n'hésitez pas à nous contacter à tout moment.",
        toAlbum: "Vers l'album",
        salesContactAlert:
          "Avec une organisation de cette taille, vous avez un contact dédié ; nous sommes heureux de gérer le concept, le design et la gestion de projet pour vous. N'hésitez pas à programmer une discussion sans engagement dans mon calendrier :",
        schedulerLink: 'Prendre rendez-vous',
        usefulLinks: 'Liens utiles',
        tutorial: {
          headline: 'Lire le tutoriel',
          subline:
            "C'est très simple : Notre tutoriel vous aide à créer votre album.",
        },
        faq: {
          headline: 'Questions fréquentes',
          subline:
            "Ici, vous trouverez nos réponses aux questions les plus fréquentes sur l'expérience de collection.",
        },
        budget: {
          headline: 'Budget',
          subline:
            'Cliquez ici pour accéder au calculateur de prix et planifier votre budget.',
        },
        firstSteps: 'Premiers pas',
        getToKnowDesigner: 'Découvrez le Designer',
        steps: {
          team: {
            headline: 'Invitez votre équipe',
            text:
              "C'est plus rapide ainsi ! Invitez les personnes qui vous aideront à créer votre album.",
          },
          albumTitle: {
            headline: "Ajustez le titre et la date de l'événement",
            text:
              "Donnez un nom à votre album et fixez la date de l'événement.",
          },
          sticker: {
            headline: 'Créez votre premier sticker',
            text:
              'Cliquez ici pour accéder à la gestion des stickers – vous pourrez y créer vos premiers stickers.',
          },
          section: {
            headline: 'Ajustez les chapitres',
            text:
              'Les chapitres structurent votre album. Cliquez ici pour accéder à la gestion des chapitres.',
          },
          stickerlink: {
            headline: "Préparez votre lien d'stickers",
            text:
              "Via le lien d'stickers, vos sticker stars peuvent télécharger leurs propres photos pour les stickers.",
          },
          image: {
            headline: "Placez une image d'arrière-plan",
            text:
              "Faites glisser une photo sur l'une des pages pour placer une photo d'arrière-plan.",
          },
        },
      },
      sidebar: {
        tabs: {
          onboarding: 'Démarrage',
          stickers: 'Stickers',
          stickerlink: "Lien d'stickers",
          background: 'Arrière-plan',
          layouts: 'Mises en page',
          styles: 'Design',
          jobs: 'Export',
          review: 'Retours',
        },
        stickers: {
          expand: 'Développer le chapitre',
          collapse: 'Réduire le chapitre',
          search: 'Rechercher des stickers',
          newStickers: 'Nouveaux stickers',
          newStickersNotification:
            "<b>%{count} nouveaux stickers</b> ont été téléchargés via votre lien d'stickers.",
          collectStickers: 'Collecter les stickers',
          unplacedStickers: 'Stickers non placés',
          stickerPlacementHelp: 'Placer les stickers',
          goToSection: 'Aller au chapitre',
          sectionHelp: 'Aide sur les chapitres',
          addSection: 'Ajouter un chapitre',
          sectionDeleteWarning:
            'Lorsque vous supprimez le chapitre, tous les stickers et pages associés seront également supprimés.',
          stickerDeleteWarning:
            "Lorsque vous supprimez l'sticker, l'image d'sticker associée sera également définitivement supprimée. (Cela s'applique aussi aux images que vous avez collectées via le lien d'stickers.)",
          defaultStickerName: 'Nouvel sticker',
        },
        stickerlink: {
          status: 'Statut',
          headline: '%{count} stickers collectés',
          sublineActive: "Votre lien d'stickers est actif.",
          sublineInactive: "Votre lien d'stickers est inactif.",
          yourLink: "Votre lien d'stickers",
          description:
            "Avec le lien d'stickers, vous pouvez facilement et rapidement collecter les photos de toutes les personnes ! Simplement copier et envoyer.",
          copyLink: 'Copier le lien dans le presse-papiers',
          isActive: "Lien d'stickers actif",
          isActiveHint: 'Activer/désactiver le téléchargement de photos',
          title: 'Demander le titre',
          titleHint:
            "Affiche un champ où le titre de l'sticker peut être saisi",
          surpriseMode: 'Mode surprise',
          surpriseModeHint:
            'Supprime toutes les références à Stickerstars et au produit',
          anonymousUrl: 'URL anonyme',
          message: 'Message personnel',
          viewLink: 'Voir le lien',
        },
        images: {
          ownImages: 'Mes images',
          stockImages: 'Bibliothèque',
          imagesPlural: 'Images',
          help: 'Aide sur les arrière-plans',
          hint:
            "Dans cette zone, vous pouvez télécharger des photos d'arrière-plan pour votre album. Vous créez et gérez les photos pour vos stickers",
          stockHint:
            "Ici, vous trouverez un large choix d'images gratuites pour votre album. Entrez simplement un terme ou un thème dans le champ de recherche. Faites glisser une image sur la zone de dessin pour la placer dans l'album.",
          maxImagesError:
            'Un maximum de %{maxImages} images peuvent être téléchargées simultanément.',
          deleteWarning:
            "Lorsque vous supprimez l'image d'arrière-plan, elle sera également supprimée de toutes les pages de l'album.",
          stockSearchPlaceholder: 'Rechercher dans la bibliothèque...',
          stickerDropWarning:
            'Voulez-vous créer un sticker à partir de cette image d\'arrière-plan ? Vous pouvez également télécharger des images d\'stickers sous "Stickers".',
        },
        layouts: {
          hint:
            "Ici, vous trouverez une variété de mises en page pour concevoir votre album. Faites-les simplement glisser sur une page (vide ou existante) de la zone de dessin pour les placer dans l'album.",
          placeWarning:
            'Lorsque vous appliquez une mise en page à une page avec du contenu, son contenu sera perdu. Êtes-vous sûr ?',
        },
        styles: {
          headline: 'Personnaliser le style',
          colors: 'Changer le style des couleurs',
          fonts: 'Changer le style de police',
          stickers: 'Changer le design des stickers',
          tryPresets: 'Essayer les modèles',
          custom: 'Entrer des couleurs personnalisées',
          changeColor: 'Choisissez une couleur à modifier',
          primaryColor: 'Couleur principale',
          secondaryColor: 'Couleur secondaire',
          tertiaryColor: 'Couleur tertiaire',
          yourColors: 'Votre style de couleurs',
        },
        review: {
          noComments:
            "Nous n'avons pas de commentaires pour vous, votre album est prêt pour l'impression !",
          sendFeedback: 'Envoyer un retour',
          printApproval: "Approbation d'impression",
          printApprovalTooltip:
            "Avez-vous pris en compte tous les commentaires ? Vous pouvez alors nous donner votre accord pour l'impression ici. Les modifications ne seront plus possibles après",
        },
      },
      stockPanel: {
        defaultQuery: 'Texture',
      },
      stickerUploadsModal: {
        selectStickerHint: 'Sélectionnez un sticker pour le modifier.',
        headline: 'Téléchargements (%{count})',
        submit: 'Ajouter %{count} stickers',
      },
      stickerForm: {
        headline: "Modifier l'sticker",
        namePlaceholder: 'Entrer le nom',
        positionPlaceholder: 'Entrer la position',
        doubleSticker: 'Double sticker',
        addImage: 'Ajouter une photo',
        changeImage: 'Changer la photo',
        downloadImage: 'Télécharger la photo',
        fitFace: 'Ajuster le visage',
        noFace: 'Aucun visage trouvé',
        stickerLayout: 'Mise en page',
        stickerCell: "Zone d'sticker",
        deleteWarning:
          "Lorsque vous supprimez l'sticker, l'image d'sticker associée sera également définitivement supprimée. (Cela s'applique aussi aux images que vous avez collectées via le lien d'stickers.)",
      },
    },
    stickerUploadForm: {
      nameLabel: 'Votre nom',
      namePlaceholder: 'Votre nom',
      dataProtection: 'Nous prenons soin de vos données.',
      dataProtectionLink: 'Ici',
      dataProtectionInfo:
        'vous trouverez des informations sur la protection des données.',
      lastNameLabel: 'Votre nom de famille (facultatif)',
      lastNamePlaceholder: 'Votre nom de famille',
      positionLabel: 'Titre/Position (facultatif)',
      positionPlaceholder: 'Votre titre',
      photoLabel: 'Votre photo',
      changePhoto: 'modifier',
      uploadInstructions:
        'Choisissez une image de votre disque dur ou utilisez votre smartphone pour en prendre une directement !',
      successHeading: 'Réussi 👍',
      successMessage:
        "Votre téléchargement a réussi, merci ! C'est maintenant le moment d'attendre avec impatience.",
      restartButton: 'recommencer',
      sectionLabel: 'Votre chapitre',
      dontKnow: 'Je ne sais pas',
      sectionHelp:
        'Si vous ne savez pas quoi mettre ici, restez simplement sur "Je ne sais pas".',
      uploadingHeading: 'Uploading...',
      uploadingMessage:
        'Merci pour votre participation — nous téléchargeons actuellement les données...',
      progressLabel: 'Progression',
      uploaderHeading: 'Bienvenue dans le téléchargeur de photos',
      backButton: 'retour',
      nextButton: 'suivant',
      privacyLink: 'Confidentialité',
      imprintLink: 'Mentions légales',
      title: 'Stickerstars | Téléchargeur de photos',
      titleWhiteLabel: 'Téléchargeur de photos',
      loading: 'Chargement...',
      albumError:
        "Cet album n'existe pas ou n'accepte pas actuellement les téléchargements.",
    },
    stickerTemplate: {
      name: 'Lisa Lässig',
      position: 'Sœur de la mariée',
    },
    profile: {
      headline: 'Mon Profil',
      subline: 'Informations sur votre compte utilisateur',
      ordersHeadline: 'Mes Commandes',
      showDetails: 'Afficher les détails',
    },
    showOrder: {
      headline: 'Détails de la commande',
      subline:
        'Ici, vous trouverez toutes les informations sur votre commande.',
      details: 'Détails',
      shipping: 'Expédition',
      timeline: 'Chronologie',
    },
    checkout: {
      backToAlbum: "retour à l'album",
      title: 'Votre Commande',
      errorMessage: "Oups, quelque chose s'est mal passé. Veuillez réessayer.",
      personalInfoMessage:
        "Super, nous avons juste besoin de quelques informations personnelles de votre part – et c'est terminé.",
      previewConfirmation: 'Tout est comme vous le souhaitez ?',
      firstNameRequired: 'Le prénom est requis',
      lastNameRequired: 'Le nom est requis',
      emailInvalid: "L'email est invalide",
      addressRequired: 'La rue et le numéro sont requis',
      zipRequired: 'Le code postal est requis',
      cityRequired: 'La ville est requise',
      countryRequired: 'Le pays est requis',
      phoneNumberFormat:
        'Le numéro de téléphone ne peut contenir que des chiffres et des espaces',
      phoneNumberLength: 'Le numéro de téléphone peut faire max. 20 caractères',
      commentLength: 'Le commentaire peut faire max. 500 caractères',
      companyRequired: "L'entreprise est requise",
      clubRequired: 'Le club est requis',
      printConsent:
        "Pour le processus de production, j'autorise Stickerstars à accéder à mon album.",
      orderWithCost: 'Commander avec paiement',
      backToOrderDetails: 'Retour aux détails de la commande',
      orderConfirmation: 'Merci, votre commande est en cours de traitement.',
      nextStepPackageContents: 'Prochaine étape : Contenu de votre colis',
      eventDateRequired: "La date de l'événement est requise",
      nextStepYourData: 'Prochaine étape : Vos données',
      backToAlbumPreview: "Retour à l'aperçu de l'album",
      contactHeading: 'Contact',
      email: 'Email',
      deliveryBillingAddress: 'Adresse de livraison/facturation',
      firstName: 'Prénom',
      lastName: 'Nom',
      streetAndNumber: 'Rue et numéro',
      addressAddition: "Complément d'adresse",
      optional: 'facultatif',
      zipCode: 'Code postal',
      city: 'Ville',
      country: 'Pays',
      phone: 'Téléphone',
      quoteNumber: 'Numéro de devis',
      remarks: 'Remarques',
      commentPlaceholder: 'Vous avez quelque chose à nous dire ? Écrivez-nous.',
      albumsLabel: "Choisir le nombre d'albums",
      albumsNeeded: "J'ai besoin de <b>%{count} albums</b>",
      chooseAdditionalOptions: 'Choisissez parmi nos options supplémentaires',
      eventLabel: 'Quand a lieu votre événement ?',
      orderSummary: 'Récapitulatif de la commande',
      priceIncludingShipping: 'Prix incluant la livraison',
      vat: 'TVA',
      totalCost: 'Coût total',
      estimatedDeliveryDate: 'date de livraison est. : <b>%{date}</b>',
      deliveryWarning:
        'Cela pourrait être juste. Veuillez nous contacter avant de passer la commande.',
      voucherInvalid: "Ce code n'est pas valide",
      voucherInactive: "Ce code n'est plus actif",
      voucherTaken: 'Ce code a déjà été utilisé',
      voucherApplied: 'Votre bon %{code} a été appliqué ! 🎉',
      haveVoucher: 'Vous avez un bon ?',
      voucherCodePlaceholder: 'Code du bon',
      organizationLabel: 'Organisation',
      countryLabels: {
        DE: 'Allemagne',
        CH: 'Suisse',
        AT: 'Autriche',
        LU: 'Luxembourg',
        FR: 'France',
        NL: 'Pays-Bas',
        BE: 'Belgique',
        IE: 'Irlande',
        FI: 'Finlande',
        IT: 'Italie',
        PL: 'Pologne',
      },
      stickerPackagingOptionsLabel: 'How should we package your stickers?',
      stickerPackagingOptions: {
        stickerpack: {
          title: 'Packs de stickers',
          text:
            "Notre classique : chaque paquet contient 4 autocollants mélangés au hasard pour un moment de surprise à l'ouverture - parfait pour une collection vivante et interactive, où les invités peuvent échanger des autocollants en double et compléter leurs albums ensemble.",
        },
        stickerboxSingleVariety: {
          title: 'Boîtes de stickers variété',
          text:
            "Notre option « carte de visite » : chaque boîte contient uniquement des stickers identiques d'un même motif. Cette variante est idéale pour les organisateurs qui souhaitent mettre à la disposition des invités leurs propres autocollants en plusieurs exemplaires.",
        },
      },
    },
    settingsModal: {
      title: 'Paramètres',
      generalTab: 'Général',
      usersTab: 'Gérer les utilisateurs',
      emailNotifications: 'Notifications par email',
      emailNotificationsHelp:
        "Nous vous enverrons par exemple une notification lorsque de nouveaux stickers sont chargés dans votre album via le lien d'stickers.",
      albumPreview: "Aperçu de l'album",
      albumPreviewHelp:
        "Activez l'aperçu de l'album pour générer un lien partageable. Le lien permet uniquement la visualisation, pas l'édition. Attention : Avec ce lien, n'importe qui peut voir votre album. Ne le partagez qu'avec des personnes de confiance.",
      language: "Langue de l'album",
      languageHelp:
        "La langue de l'album contrôle l'affichage des dates et des nombres.",
      saveError: 'Erreur lors de la sauvegarde',
      users: {
        error: "Cela n'a pas fonctionné. Veuillez réessayer plus tard.",
        inviteUser: 'Inviter un utilisateur',
        emailPlaceholder: 'Adresse email',
        sendInvitation: "Envoyer l'invitation",
        cancel: 'Annuler',
        usersHeader: 'Utilisateurs',
        ownerBadge: 'Propriétaire',
        removeUser: 'Supprimer',
        invitationsHeader: 'Invitations en attente',
        cancelInvitation: 'Annuler',
        noInvitations: 'Aucune invitation en attente.',
        invitationsError: "Une erreur s'est produite.",
      },
    },
    notifications: {
      previewMode:
        'Votre album est en mode aperçu, les modifications ne sont actuellement pas possibles.',
      autoSave: 'Vos modifications sont automatiquement enregistrées.',
    },
    invitations: {
      error: "Cela n'a pas fonctionné. Veuillez réessayer plus tard.",
      joinMessage:
        "<b>%{inviterEmail}</b> vous a invité à rejoindre le projet d'stickers <b>%{albumTitle}</b>. Pour continuer, veuillez cliquer sur le bouton et vous connecter ou créer un compte :",
      acceptInvitation: "Accepter l'invitation",
      accessInfo:
        "Après vous être connecté, vous aurez accès à l'album et aux stickers et pourrez commencer l'édition.",
      moreInfo:
        "Ici, vous trouverez plus d'informations sur STICKERSTARS et votre projet.",
      companyInfo:
        'STICKERSTARS Technologies GmbH, Wilhelm-Kabus-Str. 42/44, 10829 Berlin, Allemagne',
    },
    models: {
      title: 'Titre',
      createdAt: 'Créé le',
      album: {
        one: 'Album',
        plural: 'Albums',
        releaseDate: "Date de l'événement",
      },
      order: {
        id: 'Numéro de commande',
        lineItems: 'Articles',
        net: 'Net',
        vat: 'TVA',
        deliveryNote: 'Bon de livraison',
        invoice: 'Facture',
        comment: 'Commentaire',
      },
      lineItem: {
        quantity: 'Quantité',
      },
    },
    cookies: {
      title: '🍪 Cookies et Confidentialité',
      description:
        'Pour vous offrir la meilleure expérience de navigation possible, nous aimerions stocker quelques cookies ! Vous trouverez plus d\'informations à ce sujet dans notre <a target="__blank" aria-label="Lien vers la page de confidentialité" class="cc-link" href="https://stickerstars.de/datenschutz">Politique de confidentialité</a>. Veuillez cliquer sur "J\'accepte" pour accepter les cookies et pouvoir visiter notre site !',
      acceptAll: "J'accepte",
      settings: 'Paramètres',
      settingsModal: {
        title: 'Paramètres des cookies',
        saveSettings: 'Enregistrer les paramètres',
        acceptAll: 'Tout accepter',
        rejectAll: 'Tout refuser',
        close: 'fermer',
        intro:
          'Nous utilisons des cookies pour assurer les fonctions de base du site web et améliorer votre expérience en ligne. Vous pouvez choisir pour chaque catégorie si vous acceptez ces cookies -- ou non.',
        necessary: {
          title: 'Cookies nécessaires',
          description:
            'Les cookies nécessaires (et technologies similaires) sont requis pour le bon fonctionnement du site web et ne peuvent pas être désactivés.',
        },
        analytics: {
          title: 'Cookies pour analyses et marketing',
          description:
            'Ces cookies collectent des informations sur la façon dont vous utilisez le site web, quelles pages vous visitez et sur quels liens vous avez cliqué. Toutes les données sont anonymisées et ne peuvent pas être utilisées pour vous identifier',
        },
        moreInfo: {
          title: "Plus d'informations",
          description:
            'Pour plus d\'informations sur notre politique de cookies et nos règles de confidentialité, veuillez visiter notre page <a class="cc-link" target="_BLANK" href="https://www.stickerstars.de/datenschutz">Confidentialité</a>.',
        },
      },
    },
    footer: {
      imprint: 'Mentions légales',
      privacy: 'Confidentialité',
    },
  },
  business: {
    editor: {
      stockPanel: {
        defaultQuery: "Travail d'équipe",
      },
    },
    stickerTemplate: {
      name: 'Agathe Anklam',
      position: 'Directrice financière',
    },
    checkout: {
      organizationLabel: 'Entreprise',
    },
  },
  sports: {
    editor: {
      stockPanel: {
        defaultQuery: 'Sport',
      },
      stickerTemplate: {
        name: 'Jutta Juist',
        position: 'Milieu droit',
      },
    },
    checkout: {
      organizationLabel: 'Club / Organisation',
    },
  },
};
